import { BalanceBonusBase } from 'src/app/shared/models/hirings/bonus/balance-bonus';
import { ReserveFee } from '../fees/reserve-fee';
import { CollectiveZonesFee } from 'src/app/shared/models/fees/collective-zones-fee';
import { BonusBase } from '../hirings/bonus/bonus';
import { MultibonusBase } from '../hirings/bonus/multibonus-base';

export class ResourcePaymentMethods {
  public pendingPayment: boolean;
  public visaPayPayment: boolean;
  public gatewayPayment: boolean;
  public gatewayPaymentOneClick: boolean;
  public payPalPayment: boolean;
  public cashDroPayment: boolean;
  public payTefPayment: boolean;
  public bonuses: BonusBase[];
  public fees: ReserveFee[];
  public collectiveZonesFees: CollectiveZonesFee[];
  public balanceBonus: BalanceBonusBase;
  public multibonuses: MultibonusBase[];

  public constructor(fields?: {
    pendingPayment: boolean;
    visaPayPayment: boolean;
    gatewayPayment: boolean;
    gatewayPaymentOneClick: boolean;
    payPalPayment: boolean;
    cashDroPayment: boolean;
    payTefPayment: boolean;
    bonuses: BonusBase[];
    fees: ReserveFee[];
    collectiveZonesFees: CollectiveZonesFee[];
    balanceBonus: BalanceBonusBase;
    multibonuses: MultibonusBase[];
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class Content {
  public id: number;
  public category: string;
  public title: string;
  public summary: string;
  public publishDate: Date;
  public description: string;
  public externalLink: string;
  public PDFLink: string;
  public base64Image: string;
  public displayDate: string;

  public constructor(fields?: {
    id: number;
    category: string;
    title: string;
    summary: string;
    publishDate: Date;
    description: string;
    externalLink: string;
    PDFLink: string;
    base64Image: string;
    displayDate?: string;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

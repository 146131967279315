import { ModuleApp } from 'src/app/shared/models/configuration-app/module-app';
import { GroupModulesApp } from './group-modules-app';
import { Location, MapPoint } from './location';

export class ConfigurationApp {
  public name: string;
  public logoURL: string;
  public loginBackgroundURL: string;
  public homeBackgroundURL: string;
  public descriptionCenter: string;
  public dataProtectionConditions: string;
  public useConditions: string;
  public guestModeEnabled: boolean;
  public storeOptProductsEnabled: boolean;
  public storeOptBonusEnabled: boolean;
  public storeOptHiringsEnabled: boolean;
  public storeOptEntriesEnabled: boolean;
  public onlineRegistrationAndroidEnabled: boolean;
  public onlineRegistrationIOSEnabled: boolean;
  public entriesSellingEnabled: boolean;
  public loginKioskQREnabled: boolean;
  public loginKioskDeviceEnabled: boolean;
  public loginStoreOptProductsEnabled: boolean;
  public loginStoreOptEntriesEnabled: boolean;
  public linkedCentersEnabled: boolean;
  public linkOnlineRegistration: string;
  public linkWebCenter: string;
  public linkFacebook: string;
  public linkInstagram: string;
  public linkTwitter: string;
  public linkYoutube: string;
  public linkTikTok: string;
  public linkTelegram: string;
  public linkLinkedIn: string;
  public linkSnapchat: string;
  public whatsAppCenter: string;
  public phoneCenter: string;
  public emailCenter: string;
  public colorPrimaryBase: string;
  public colorPrimaryBaseRGB: string;
  public colorPrimaryContrast: string;
  public colorPrimaryContrastRGB: string;
  public colorPrimaryShade: string;
  public colorPrimaryTint: string;
  public colorBackgroundSolidBase: string;
  public colorBackgroundSolidBaseRGB: string;
  public colorBackgroundSolidContrast: string;
  public colorBackgroundSolidContrastRGB: string;
  public colorBackgroundSolidShade: string;
  public colorBackgroundSolidTint: string;
  public colorHeaderBase: string;
  public colorHeaderBaseRGB: string;
  public colorHeaderContrast: string;
  public colorHeaderContrastRGB: string;
  public colorHeaderShade: string;
  public colorHeaderTint: string;
  public colorButtonMainBase: string;
  public colorButtonMainBaseRGB: string;
  public colorButtonMainContrast: string;
  public colorButtonMainContrastRGB: string;
  public colorButtonMainShade: string;
  public colorButtonMainTint: string;
  public colorListBackgroundBase: string;
  public colorListBackgroundBaseRGB: string;
  public colorListBackgroundContrast: string;
  public colorListBackgroundContrastRGB: string;
  public colorListBackgroundShade: string;
  public colorListBackgroundTint: string;
  public colorButtonPositiveBase: string;
  public colorButtonPositiveBaseRGB: string;
  public colorButtonPositiveContrast: string;
  public colorButtonPositiveContrastRGB: string;
  public colorButtonPositiveShade: string;
  public colorButtonPositiveTint: string;
  public colorButtonNegativeBase: string;
  public colorButtonNegativeBaseRGB: string;
  public colorButtonNegativeContrast: string;
  public colorButtonNegativeContrastRGB: string;
  public colorButtonNegativeShade: string;
  public colorButtonNegativeTint: string;
  public fontFamily: string;
  public fontSize: string;
  public colorText: string;
  public colorBackground: string;
  public colorError: string;
  public colorBorder: string;
  public colorListBorder: string;
  public textColorListTitle: string;
  public textColorListGroupTitle: string;
  public textColorPageDetailTitle: string;
  public colorDatatableBorder: string;
  public colorDatatableHeaderBackground: string;
  public colorDatatableHeaderText: string;
  public colorGraphLine1: string;
  public colorGraphLine2: string;
  public colorBackgroundBadge: string;
  public colorFontBadge: string;
  public logoHomeWidth: number;
  public locationFocus: MapPoint;
  public locationZoom: number;
  public locations: Location[];
  public images: string[];
  public modulesHome: ModuleApp[];
  public modulesMenu: ModuleApp[];
  public groupsModulesMenu: GroupModulesApp[];

  public constructor(fields?: {
    name: string;
    logoURL: string;
    loginBackgroundURL: string;
    homeBackgroundURL: string;
    descriptionCenter: string;
    dataProtectionConditions: string;
    useConditions: string;
    guestModeEnabled: boolean;
    storeOptProductsEnabled: boolean;
    storeOptBonusEnabled: boolean;
    storeOptHiringsEnabled: boolean;
    storeOptEntriesEnabled: boolean;
    onlineRegistrationAndroidEnabled: boolean;
    onlineRegistrationIOSEnabled: boolean;
    entriesSellingEnabled: boolean;
    loginKioskQREnabled: boolean;
    loginKioskDeviceEnabled: boolean;
    loginStoreOptProductsEnabled: boolean;
    loginStoreOptEntriesEnabled: boolean;
    linkedCentersEnabled: boolean;
    linkOnlineRegistration: string;
    linkWebCenter: string;
    linkFacebook: string;
    linkInstagram: string;
    linkTwitter: string;
    linkYoutube: string;
    linkTikTok: string;
    linkTelegram: string;
    linkLinkedIn: string;
    linkSnapchat: string;
    whatsAppCenter: string;
    phoneCenter: string;
    emailCenter: string;
    colorPrimaryBase: string;
    colorPrimaryBaseRGB: string;
    colorPrimaryContrast: string;
    colorPrimaryContrastRGB: string;
    colorPrimaryShade: string;
    colorPrimaryTint: string;
    colorBackgroundSolidBase: string;
    colorBackgroundSolidBaseRGB: string;
    colorBackgroundSolidContrast: string;
    colorBackgroundSolidContrastRGB: string;
    colorBackgroundSolidShade: string;
    colorBackgroundSolidTint: string;
    colorHeaderBase: string;
    colorHeaderBaseRGB: string;
    colorHeaderContrast: string;
    colorHeaderContrastRGB: string;
    colorHeaderShade: string;
    colorHeaderTint: string;
    colorButtonMainBase: string;
    colorButtonMainBaseRGB: string;
    colorButtonMainContrast: string;
    colorButtonMainContrastRGB: string;
    colorButtonMainShade: string;
    colorButtonMainTint: string;
    colorListBackgroundBase: string;
    colorListBackgroundBaseRGB: string;
    colorListBackgroundContrast: string;
    colorListBackgroundContrastRGB: string;
    colorListBackgroundShade: string;
    colorListBackgroundTint: string;
    colorButtonPositiveBase: string;
    colorButtonPositiveBaseRGB: string;
    colorButtonPositiveContrast: string;
    colorButtonPositiveContrastRGB: string;
    colorButtonPositiveShade: string;
    colorButtonPositiveTint: string;
    colorButtonNegativeBase: string;
    colorButtonNegativeBaseRGB: string;
    colorButtonNegativeContrast: string;
    colorButtonNegativeContrastRGB: string;
    colorButtonNegativeShade: string;
    colorButtonNegativeTint: string;
    fontFamily: string;
    fontSize: string;
    colorText: string;
    colorBackground: string;
    colorError: string;
    colorBorder: string;
    colorListBorder: string;
    textColorListTitle: string;
    textColorListGroupTitle: string;
    textColorPageDetailTitle: string;
    colorDatatableBorder: string;
    colorDatatableHeaderBackground: string;
    colorDatatableHeaderText: string;
    colorGraphLine1: string;
    colorGraphLine2: string;
    colorBackgroundBadge: string;
    colorFontBadge: string;
    selectZonesParters: boolean;
    logoHomeWidth: number;
    locationFocus: MapPoint;
    locationZoom: number;
    locations: Location[];
    images: string[];
    modulesHome: ModuleApp[];
    modulesMenu: ModuleApp[];
    groupsModulesMenu: GroupModulesApp[];
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class FeePendingPayment {
  public id: number;
  public activityId: number;
  public startDate: Date;
  public endDate: Date;
  public name: string;
  public amount: number;

  public constructor(fields?: {
    id: number;
    activityId: number;
    startDate: Date;
    endDate: Date;
    name: string;
    amount: number;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

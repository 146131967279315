export class MemberLinkedMember {
  public idCenter: number;
  public centerName: string;
  public idMember: number;
  public principal: boolean;

  public constructor(fields?: {
    idCenter: number;
	centerName: string;
	idMember: number;
	principal: boolean;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

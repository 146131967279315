import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storage: Storage | null = null;

  constructor(private storageCons: Storage) { }

  async init() {
    if(this.storage != null) {
      return;
    }
    await this.storageCons.defineDriver(CordovaSQLiteDriver);
    const storageAux = await this.storageCons.create();
    this.storage = storageAux;
  }

  public async set(key: string, value: any): Promise<any> {
    await this.init();
    return await this.storage?.set(key, value);
  }

  public async get(key: string): Promise<any> {
    await this.init();
    return await this.storage?.get(key);
  }

  public async remove(key: string): Promise<any> {
    await this.init();
    return await this.storage?.remove(key);
  }

}

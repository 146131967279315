import { NotificationImportanceEnum } from './notification-importance-enum';
import { NotificationTypeEnum } from './notification-type-enum';

export class Notification {
  public id: number;
  public title: string;
  public message: string;
  public detail: string;
  public url: string;
  public read: boolean;
  public publishDate: Date;
  public type: NotificationTypeEnum;
  public importance: NotificationImportanceEnum;

  public displayDate: string;
  public antiquityDays: number;

  public constructor(fields?: {
    id: number;
    title: string;
    message: string;
    detail: string;
    url: string;
    read: boolean;
    publishDate: Date;
    type: NotificationTypeEnum;
    importance: NotificationImportanceEnum;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes, NoPreloading } from '@angular/router';

export const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'activities',
    loadChildren: () => import('./activities/activities.module').then((m) => m.ActivitiesModule),
  },
  {
    path: 'capacity',
    loadChildren: () => import('./capacity/capacity.module').then((m) => m.CapacityModule),
  },
  {
    path: 'center',
    loadChildren: () => import('./center/center.module').then((m) => m.CenterModule),
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
  },
  {
    path: 'classes',
    loadChildren: () => import('./classes/classes.module').then((m) => m.ClassesModule),
  },
  {
    path: 'collective-zones',
    loadChildren: () => import('./reserves/collective-zones/collective-zones.module').then((m) => m.CollectiveZonesModule),
  },
  {
    path: 'configuration',
    loadChildren: () => import('./configuration/configuration.module').then((m) => m.ConfigurationModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'contents',
    loadChildren: () => import('./contents/contents.module').then((m) => m.ContentsModule),
  },
  {
    path: 'courses',
    loadChildren: () => import('./courses/courses.module').then((m) => m.CoursesModule),
  },
  {
    path: 'domotic',
    loadChildren: () => import('./gsm-keys-management/gsmkeysManagement.module').then((m) => m.GSMKeysManagementModule),
  },
  {
    path: 'employees',
    loadChildren: () => import('./employees/employees.module').then((m) => m.EmployeesModule),
  },
  {
    path: 'health',
    loadChildren: () => import('./health/health.module').then((m) => m.HealthModule),
  },
  {
    path: 'invoices',
    loadChildren: () => import('./invoices/invoices.module').then((m) => m.InvoicesModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then((m) => m.NotificationsModule),
  },
  {
    path: 'nutrition',
    loadChildren: () => import('./nutrition/nutrition.module').then((m) => m.NutritionModule),
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payments.module').then((m) => m.PaymentsModule),
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'qr',
    loadChildren: () => import('./qr/qr.module').then((m) => m.QrModule),
  },
  {
    path: 'services',
    loadChildren: () => import('./reserves/services/services.module').then((m) => m.ServicesModule),
  },
  {
    path: 'shop',
    loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule),
  },
  {
    path: 'training',
    loadChildren: () => import('./training/training.module').then((m) => m.TrainingModule),
  },
  {
    path: 'tutored',
    loadChildren: () => import('./tutored/tutored.module').then((m) => m.TutoredModule),
  },
  {
    path: 'tickets',
    loadChildren: () => import('./tickets/tickets.module').then((m) => m.TicketsModule),
  },
  {
    path: 'zones',
    loadChildren: () => import('./reserves/zones/zones.module').then((m) => m.ZonesModule),
  },
  {
    path: '',
    loadChildren: () => import('./core/core.module').then((m) => m.CoreModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading, initialNavigation: 'disabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { File } from '@awesome-cordova-plugins/file/ngx';

// Servicios
import { InitAppService } from './core/services/init-app/init-app.service';
import { NavigationService } from './shared/services/navigation/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private platform: Platform,
    private file: File,
    private initAppService: InitAppService,
    private navigationService: NavigationService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initAppService.initializeApp();

    if (this.platform.is('cordova')) {
      if (this.platform.is('ios')) {
        this.file.listDir(this.file.documentsDirectory, '')
          .then(
            result => result.forEach((file) => {
              if (file.isFile) {
                this.file.removeFile(this.file.documentsDirectory, file.name)
                  .then()
                  .catch(err => console.log(err));
              }
            })
          )
          .catch(err => console.log(err));
      }

      this.platform.backButton.subscribeWithPriority(10, async () => {
        this.navigationService.back();
      });

      (navigator as any).splashscreen.hide();
    }
  }
}

import { BonusTypeEnum } from './bonus-type.enum';

export class BonusBase {
  public id: number;
  public name: string;
  public sessions: number;

  public constructor(fields?: { id: number; name: string; sessions: number }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class Bonus extends BonusBase {
  public type: BonusTypeEnum;
  public expiringDate: Date;

  public constructor(fields?: {
    id: number;
    name: string;
    sessions: number;
    type: BonusTypeEnum;
    expiringDate: Date;
  }) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }
}

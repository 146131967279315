import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController, AlertButton } from '@ionic/angular';
import { LanguageService } from '../language/language.service';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private isLoading: boolean = false;

  constructor(
    private alertCtrl: AlertController,
    private languageService: LanguageService,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController
  ) {}

  /**
   * Funcion click del boton OK mostrado por la funcion presentAlert. Necesario extraer función
   * fuera del create para realizar pruebas unitarias.
   */
  public presentAlertButtonOkClick: any;

  /**
   * Muestra una alerta
   *
   * @param title Titulo
   * @param msg Mensaje
   * @param textOk Texto mostrado en el boton
   */
  public presentAlert(fields: { msg: string; title?: string; textOk?: string; backdropDismiss?: boolean }): Promise<void> {
    this.dismissLoading();

    return new Promise((resolve) => {
      this.presentAlertButtonOkClick = resolve;

      this.alertCtrl
        .create({
          header: fields.title ? fields.title : '',
          message: fields.msg,
          backdropDismiss: fields.backdropDismiss ? fields.backdropDismiss : false,
          buttons: [
            {
              text: fields.textOk ? fields.textOk : this.languageService.getText('GLOBAL.ACCEPT'),
              handler: (): any => {
                this.presentAlertButtonOkClick();
              },
            },
          ],
          mode: 'ios',
          cssClass: 'custom-alert' + (fields.title ? '' : ' no-header'),
        })
        .then((alertControl) => {
          alertControl.present();
        });
    });
  }

  /**
   * Funciones click del los botones OK y Cancelar mostrado por la funcion presentConfirm. Necesario extraer función
   * fuera del create para realizar pruebas unitarias.
   */
  public presentConfirmButtonOkClick: any;
  public presentConfirmButtonCancelClick: any;

  /**
   * Muestra un modal para confirmar con OK/Cancelar
   *
   * @param title Titulo
   * @param msg Mensaje
   * @param textOk Texto mostrado en el boton afirmativo
   * @param textCancel Texto mostrado en el boton negativo
   */
  public presentConfirm(fields: { title: string; msg: string; textOk?: string; textCancel?: string; cssClass?: string }): Promise<boolean> {
    this.dismissLoading();

    return new Promise((resolve) => {
      this.presentConfirmButtonOkClick = resolve;
      this.presentConfirmButtonCancelClick = resolve;

      this.alertCtrl
        .create({
          header: fields.title,
          message: fields.msg,
          backdropDismiss: false,
          buttons: [
            {
              text: fields.textCancel ? fields.textCancel : this.languageService.getText('GLOBAL.CANCEL'),
              handler: (): any => {
                this.presentConfirmButtonCancelClick(false);
              },
            },
            {
              text: fields.textOk ? fields.textOk : this.languageService.getText('GLOBAL.ACCEPT'),
              handler: (): any => {
                this.presentConfirmButtonOkClick(true);
              },
              role: 'accept',
            },
          ],
          mode: 'ios',
          cssClass: 'custom-alert' + (fields.cssClass ? ' ' + fields.cssClass : ''),
        })
        .then((confirm) => {
          confirm.present();
        });
    });
  }

  /**
   * Funciones click del los botones OK y Cancelar mostrado por la funcion presentPromptText. Necesario extraer función
   * fuera del create para realizar pruebas unitarias.
   */
  public presentPromptTextButtonOkClick: any;
  public presentPromptTextButtonCancelClick: any;

  /**
   * Muestra un modal pidiento un valor de texto
   *
   * @param title Titulo
   * @param msg Mensaje
   * @param placeholderStr Placeholder del campo de texto
   * @param textFieldType Tipo campo de texto
   * @param textOk Texto mostrado en el boton afirmativo
   * @param textCancel Texto mostrado en el boton negativo
   */
  public presentPromptText(fields: {
    title?: string;
    msg?: string;
    placeholderStr?: string;
    textFieldType: 'number' | 'text';
    textOk?: string;
    textCancel?: string;
    min?: number;
    max?: number;
    hideCancel?: boolean;
    regex?: RegExp;
    regexErrorMsg?: string;
    cssClass?: string;
  }): Promise<string> {
    this.dismissLoading();

    return new Promise((resolve) => {
      this.presentPromptTextButtonOkClick = resolve;
      this.presentPromptTextButtonCancelClick = resolve;

      const buttons: AlertButton[] = [];

      if (!fields.hideCancel) {
        buttons.push({
          text: fields.textCancel ? fields.textCancel : this.languageService.getText('GLOBAL.CANCEL'),
          handler: () => {
            this.presentPromptTextButtonCancelClick(null);
          },
        });
      }

      buttons.push({
        text: fields.textOk ? fields.textOk : this.languageService.getText('GLOBAL.OK'),
        handler: (text) => {
          if (fields.min && fields.textFieldType === 'number' && parseInt(text.text, 10) < fields.min) {
            this.presentAlert({
              title: this.languageService.getText('GLOBAL.INFORMATION'),
              msg: this.languageService.getText('ERRORS.ALERTS.MIN_VALUE').replace('@p1', fields.min),
            });
            return false;
          }

          if (fields.max && fields.textFieldType === 'number' && parseInt(text.text, 10) > fields.max) {
            this.presentAlert({
              title: this.languageService.getText('GLOBAL.INFORMATION'),
              msg: this.languageService.getText('ERRORS.ALERTS.MAX_VALUE').replace('@p1', fields.max),
            });
            return false;
          }

          if (text.text && fields.regex && fields.regexErrorMsg && fields.textFieldType === 'text' && !fields.regex.test(text.text)) {
            this.presentAlert({
              title: this.languageService.getText('GLOBAL.INFORMATION'),
              msg: this.languageService.getText(fields.regexErrorMsg),
            });
            return false;
          }

          this.presentPromptTextButtonOkClick(text.text);
        },
      });

      this.alertCtrl
        .create({
          header: fields.title,
          message: fields.msg,
          inputs: [
            {
              name: 'text',
              type: fields.textFieldType,
              placeholder: fields.placeholderStr ? fields.placeholderStr : '',
            },
          ],
          buttons,
          mode: 'ios',
          cssClass: fields.cssClass + (fields.title ? '' : ' no-header') + (fields.msg ? '' : ' no-message'),
        })
        .then((promptController) => {
          promptController.present();
        });
    });
  }

  /**
   * Muestra el componente loading
   *
   * @param text Mensaje a mostrar o null para mensaje global definido por defecto
   */
  public async presentLoading(text?: string): Promise<void> {
    const loading: HTMLIonLoadingElement = await this.loadingCtrl.getTop();
    if (!loading) {
      this.isLoading = true;
      return await this.loadingCtrl
        .create({
          spinner: 'bubbles',
          message: text ? text : this.languageService.getText('GLOBAL.LOADING'),
          cssClass: ['custom-loading'],
          translucent: true,
        })
        .then((a) => {
          a.present().then(() => {
            if (!this.isLoading) {
              a.dismiss();
            }
          });
        });
    }
  }

  /**
   * Esconde el componente loading previamente creado
   */
  public async dismissLoading(): Promise<void> {
    this.isLoading = false;
    const loading: HTMLIonLoadingElement = await this.loadingCtrl.getTop();
    if (loading) {
      await this.loadingCtrl.dismiss();
    }
  }

  /**
   * Muestra el componente toast
   *
   * @param msg Mensaje a mostrar
   * @param position Posición: top, bottom, middle
   */
  public async presentToast(fields: {
    msg: string;
    header?: string;
    position?: 'top' | 'bottom' | 'middle';
    duration?: number;
    color?: string;
  }): Promise<void> {
    const toast = await this.toastCtrl.create({
      header: fields.header ? fields.header : null,
      message: fields.msg,
      position: fields.position ? fields.position : 'top',
      color: fields.color ? fields.color : 'primary-inverse',
      duration: fields.duration ? fields.duration : 2000,
    });
    toast.present();
  }

  /**
   * Muestra el componente toast con boton cerrar
   *
   * @param msg Mensaje a mostrar
   * @param header Título del toast
   * @param position Posición: top, bottom, middle
   */
  public presentToastWithClose(msg: string, header?: string, position?: 'top' | 'bottom' | 'middle', color?: string): Promise<void> {
    return new Promise((resolve) => {
      this.toastCtrl
        .create({
          header: header ? header : null,
          message: msg,
          position: position ? position : 'top',
          color: color ? color : 'primary-inverse',
          buttons: [
            {
              icon: 'close',
              role: 'cancel',
            },
          ],
        })
        .then((toastController) => {
          toastController.onDidDismiss().then(() => {
            resolve();
          });

          toastController.present();
        });
    });
  }

  /**
   * Muestra el componente toast con el icono indicado
   *
   * @param msg Mensaje a mostrar
   * @param header Título del toast
   * @param position Posición: top, bottom, middle
   * @param buttons Se entiende de sólo un elemento que será el icono y sin acción click
   */
  public presentToastWithIcon(
    msg: string,
    header?: string,
    position?: 'top' | 'bottom' | 'middle',
    icon?: string,
    duration?: number,
    color?: string
  ): Promise<void> {
    return new Promise((resolve) => {
      this.toastCtrl
        .create({
          header: header ? header : null,
          message: msg,
          position: position ? position : 'top',
          color: color ? color : 'primary-inverse',
          buttons: [
            {
              side: 'end',
              icon: icon ? icon : 'checkmark-circle-outline',
            },
          ],
          duration: duration ? duration : 2000,
        })
        .then((toastController) => {
          toastController.onDidDismiss().then(() => {
            resolve();
          });

          toastController.present();
        });
    });
  }
}

export class MapPoint {
  public lat: number;
  public lng: number;

  public constructor(fields?: { lat: number; lng: number }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class Location extends MapPoint {
  public description: string;

  public constructor(fields?: {
    description: string;
    lat: number;
    lng: number;
  }) {
    super();
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export enum ModuleAppTypeEnum {
  classesReserve = 1,
  zonesReserve = 2,
  servicesReserve = 3,
  training = 4,
  shop = 5,
  board = 6,
  courses = 7,
  activities = 8,
  capacity = 9,
  link = 10,
  myAccount = 11,
  myHires = 12,
  myClasses = 13,
  myBonuses = 14,
  myServices = 15,
  myZones = 16,
  myShopping = 17,
  myCourses = 18,
  pendingPayments = 19,
  privacyPolicy = 20,
  aboutUs = 21,
  classesAttendanceControl = 22,
  nutrition = 23,
  changePassword = 24,
  goOut = 25,
  health = 26,
  team = 27,
  qr = 28,
  contact = 29,
  distributor = 30,
  distributorLink = 31,
  tutored = 32,
  chat = 33,
  gsmkeysManagement = 34,
  configuration = 35,
  contactForm = 36,
  termsOfUse = 37,
  coursesAttendanceControl = 38,
  myPersonalData = 39,
  openApp = 40,
  collectiveZonesReserve = 41,
  myCollectiveZones = 42,
  employeeSchedule = 43,
  myInvoices = 44,
  myTickets = 45,
}

export const isModuleApp = (value: number): boolean => {
  if (!(value in ModuleAppTypeEnum)) {
    return false;
  }

  return true;
};

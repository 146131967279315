import { GlobalService } from 'src/app/shared/services/global/global.service';
import { InjectorService } from './../injector/inyector.service';
import { LanguageService } from './../language/language.service';
import { CrashesMonitorService } from 'src/app/shared/services/crashes-monitor/crashes-monitor.service';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataHttpService {
  constructor(private http: HttpClient, private global: GlobalService) {}

  /**
   * Peticion HTTP Get
   *
   * @param url Dirección extremo URL
   */
  public doGetImage(url: string): Observable<any> {
    const options: any = {
      headers: new HttpHeaders(),
      observe: 'response',
      responseType: 'text',
    };

    return this.http.get(url, options).pipe(catchError(this.handleError));
  }

  /**
   * Peticion HTTP Get
   *
   * @param endpoint Dirección extremo URL
   * @param apiVersion Versión de la API
   * @param params Parametros Http
   */
  public doGet(
    endpoint: string,
    apiVersion: 'v1.0' | 'v2.0',
    params?: HttpParams
  ): Observable<HttpEvent<any>> {
    const url = this.global.apiUrl + apiVersion + endpoint;
    const options: any = this.generateOptionsRequest(params);
    return this.http
      .get<HttpEvent<any>>(url, options)
      .pipe(catchError(this.handleError));
  }

  /**
   * Peticion HTTP Get
   *
   * @param endpoint Dirección extremo URL
   * @param apiVersion Versión de la API
   * @param params Parametros Http
   */
  public doGetDoc(
    endpoint: string,
    apiVersion: 'v1.0' | 'v2.0',
    params?: HttpParams
  ): Observable<any> {
    const url = this.global.apiUrl + apiVersion + endpoint;
    const options: any = this.generateOptionsRequestDoc(params);
    return this.http.get(url, options).pipe(catchError(this.handleError));
  }

  /**
   * Peticion HTTP Post
   *
   * @param endpoint Dirección extremo URL
   * @param apiVersion Versión de la API
   * @param body Cuerpo
   */
  public doPost(
    endpoint: string,
    apiVersion: 'v1.0' | 'v2.0',
    body: any
  ): Observable<any> {
    const url = this.global.apiUrl + apiVersion + endpoint;
    const options: any = this.generateOptionsRequest(null);
    return this.http
      .post(url, body, options)
      .pipe(catchError(this.handleError));
  }

  /**
   * Peticion HTTP Post
   *
   * @param endpoint Dirección extremo URL
   * @param apiVersion Versión de la API
   * @param body Cuerpo
   */
  public doPostDoc(
    endpoint: string,
    apiVersion: 'v1.0' | 'v2.0',
    body: any
  ): Observable<any> {
    const url = this.global.apiUrl + apiVersion + endpoint;
    const options: any = this.generateOptionsRequestDoc(null);
    return this.http
      .post(url, body, options)
      .pipe(catchError(this.handleError));
  }

  /**
   * Peticion HTTP Put
   *
   * @param endpoint Dirección extremo URL
   * @param apiVersion Versión de la API
   * @param body Cuerpo
   */
  public doPut(
    endpoint: string,
    apiVersion: 'v1.0' | 'v2.0',
    body?: any
  ): Observable<any> {
    const url = this.global.apiUrl + apiVersion + endpoint;
    const options: any = this.generateOptionsRequest(null);
    return this.http.put(url, body, options).pipe(catchError(this.handleError));
  }

  /**
   * Peticion HTTP Delete
   *
   * @param endpoint Dirección extremo URL
   * @param apiVersion Versión de la API
   * @param body Cuerpo
   */
  public doDelete(
    endpoint: string,
    apiVersion: 'v1.0' | 'v2.0'
  ): Observable<any> {
    const url = this.global.apiUrl + apiVersion + endpoint;
    const options: any = this.generateOptionsRequest(null);
    return this.http.delete(url, options).pipe(catchError(this.handleError));
  }

  /**
   * Genera opciones de la petición HTTP: cabeceras y parámetros.
   *
   * @param params Parametros Http
   */
  private generateOptionsRequest(params?: HttpParams): any {
    const options: any = {};
    let headers: HttpHeaders = new HttpHeaders();

    options.observe = 'response';

    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept-Language', this.global.language);

    if (this.global.token && this.global.token !== '') {
      headers = headers.append('Authorization', 'Bearer ' + this.global.token);
    }

    options.headers = headers;

    if (params != null) {
      options.params = params;
    }

    return options;
  }

  /**
   * Genera opciones de la petición HTTP: cabeceras y parámetros.
   *
   * @param params Parametros Http
   */
  private generateOptionsRequestDoc(params?: HttpParams): any {
    const options: any = {};
    let headers: HttpHeaders = new HttpHeaders();

    options.observe = 'response';

    headers = headers.append('Accept-Language', this.global.language);
    headers = headers.append('Accept', 'application/pdf');

    if (this.global.token && this.global.token !== '') {
      headers = headers.append('Authorization', 'Bearer ' + this.global.token);
    }

    options.headers = headers;
    options.responseType = 'blob';

    if (params != null) {
      options.params = params;
    }

    return options;
  }

  /**
   * Manejador de errores de las peticiones HTTP
   *
   * @param error Error
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    const errorRet = { msg: '', code: -1, data: null };

    if (error.error instanceof ErrorEvent) {
      // Error en el lado cliente o en la red
      if (!environment.production) {
        console.error('ERROR -> ' + JSON.stringify(error));
      }
      errorRet.msg = error.error.message ? error.error.message : error.message;
    } else {
      // El backend retorno un codigo de respuesta incorrecto.

      if (!environment.production) {
        console.error(
          'Backend returned code ' + error.status + ', body was: ' + error.error
        );
      }

      errorRet.code = error.status;
      errorRet.msg = error.message;
      errorRet.data = error.error;

      if (
        error.status === 401 &&
        !error.url.includes('acceso/usuarios/login')
      ) {
        const globalService: GlobalService =
          InjectorService.injector.get(GlobalService);
        const dataHttpService: DataHttpService =
          InjectorService.injector.get(DataHttpService);
        const crashesMonitorService: CrashesMonitorService =
          InjectorService.injector.get(CrashesMonitorService);
        const languageService: LanguageService =
          InjectorService.injector.get(LanguageService);

        Promise.all([
          globalService.removeToken(),
          globalService.removeUser(),
        ]).then(() => {
          crashesMonitorService.reportAndShowException({
            className: 'DataHttpService',
            methodName: 'handleError (2)',
            errStr: JSON.stringify(errorRet),
            msg: languageService.getText('GLOBAL.ERROR_SESSION_END'),
            navigation: 'start',
          });

          dataHttpService
            .doPost(
              '/acceso/clientes/login',
              'v1.0',
              globalService.apiClientAccess
            )
            .subscribe((response: HttpResponse<any>) => {
              if (response.status === 200) {
                globalService.setToken(response.body);
              }
            });
        });
      }
    }

    return throwError(errorRet);
  }
}

import { DataHttpService } from './../data-http/data-http.service';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginUser } from '../../models/users/login-user';
import { LoginParam } from '../../models/users/login-param';
import { DeviceLoginParam } from '../../models/users/device-login-param';
import { LoginUserResult } from '../../models/users/login-user-result';
import { ChangePasswordParam } from '../../models/users/change-password-param';
import { ResetPasswordGenerateCodeParam } from '../../models/users/reset-password-generate-code-param';
import { ResetPasswordParam } from '../../models/users/reset-password-param';
import { ApiClientAccess } from '../../models/others/api-client-access';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private dataService: DataHttpService) {}

  /**
   * Valida el token de acceso a la API
   */
  public checkToken(): Observable<boolean> {
    return this.dataService.doGet('/acceso/checkToken', 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        if (response.body === 'Ok') {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  /**
   * Devuelve la información del socio a partir del token
   */
  public getMemberInformationToken(): Observable<LoginUser> {
    return this.dataService.doGet('/acceso/tokenInfo', 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        if (response.body) {
          return new LoginUser({
            idUser: response.body.idUsuario,
            idCenter: response.body.idCentro,
            idMember: response.body.idSocio
          });
        }

        return null;
      })
    );
  }

  /**
   * Obtiene token de acceso a la API.
   *
   * @param accessData Objeto que contiene usuario y contraseña.
   */
  public doPostClientData(accessData: ApiClientAccess): Observable<string> {
    return this.dataService.doPost('/acceso/clientes/login', 'v1.0', accessData).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );
  }

  /**
   * Obtiene token de usuario.
   *
   * @param loginParam Objeto que contiene usuario y contraseña.
   */
  public doPostUserData(loginParam: LoginParam): Observable<LoginUserResult> {
    return this.dataService.doPost('/acceso/usuarios/login', 'v1.0', loginParam).pipe(
      map((response: HttpResponse<any>) => {
        return new LoginUserResult({
          token: response.body.token,
          loginUser: new LoginUser({
            idUser: response.body.idUsuario,
            idMember: response.body.idSocio,
            idCenter: response.body.idCentro
          })
        });
      })
    );
  }

  /**
   * Obtiene token de usuario a partir de un dispositivo.
   *
   * @param deviceLoginParam Objeto que contiene tipo de dispositivo y código.
   */
  public doPostUserDeviceData(idCenter: number, deviceLoginParam: DeviceLoginParam): Observable<LoginUserResult> {
    return this.dataService.doPost('/acceso/centros/' + idCenter + '/logindispositivo', 'v1.0', deviceLoginParam).pipe(
      map((response: HttpResponse<any>) => {
        return new LoginUserResult({
          token: response.body.token,
          loginUser: new LoginUser({
            idUser: response.body.idUsuario,
            idMember: response.body.idSocio,
            idCenter: response.body.idCentro
          })
        });
      })
    );
  }

  /**
   * Lanza petición de generación de código para poder establecer una nueva contraseña.
   *
   * @param resetPasswordGenerateCodeParam Objeto que contiene el usuario que quiere establecer una nueva contraseña.
   */
  public doResetPasswordGenerateCode(
    resetPasswordGenerateCodeParam: ResetPasswordGenerateCodeParam
  ): Observable<boolean> {
    return this.dataService.doPost('/acceso/usuarios/generarCodigoRestablecerPassword', 'v1.0', resetPasswordGenerateCodeParam).pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 200) {
            return true;
          }
        })
      );
  }

  /**
   * Lanza petición de cambio de contraseña para una contraseña actual y una nueva.
   *
   * @param idUser Id del usuario a cambiar la contraseña
   * @param changePasswordParams Objeto que contiene contraseña actual y contraseña nueva.
   */
  public doChangePassword(
    idUser: number,
    changePasswordParam: ChangePasswordParam
  ): Observable<boolean> {
    return this.dataService.doPut('/acceso/usuarios/' + idUser + '/password', 'v1.0', changePasswordParam).pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 200) {
            return true;
          }
        })
      );
  }

  /**
   * Lanza petición de establecer una nueva contraseña.
   *
   * @param resetPasswordParam Objeto que contiene el código de verificación y la nueva contraseña.
   */
  public doResetPassword(
    resetPasswordParam: ResetPasswordParam
  ): Observable<boolean> {
    return this.dataService.doPut('/acceso/usuarios/restablecerPassword', 'v1.0', resetPasswordParam).pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 200) {
            return true;
          }
        })
      );
  }

  /**
   * Refresca el token de acceso a la API
   */
  public refreshToken(): Observable<string> {
    return this.dataService.doGet('/acceso/refrescarToken', 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        if (response.body) {
          return response.body;
        }
      })
    );
  }

}

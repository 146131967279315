import { ModuleAppTypeEnum } from './module-app-type-enum';

export class ModuleApp {
  public id: ModuleAppTypeEnum;
  public text: string;
  public homeButtonColumns: number;
  public iconColor: string;
  public textColor: string;
  public guestModeEnabled: boolean;
  public iconLink: string;
  public isNovelty: boolean;
  public showIcon: boolean;
  public backgroundColor: string;
  public borderRadius: string;
  public borderColor: string;
  public borderStyle: string;
  public borderWidth: string;
  public webVisible: boolean;
  public androidVisible: boolean;
  public iosVisible: boolean;
  public kioskVisible: boolean;

  public constructor(fields?: {
    id: number;
    text: string;
    homeButtonColumns: number;
    iconColor: string;
    textColor: string;
    guestModeEnabled: boolean;
    iconLink: string;
    isNovelty: boolean;
    showIcon: boolean;
    backgroundColor: string;
    borderRadius: string;
    borderColor: string;
    borderStyle: string;
    borderWidth: string;
    webVisible: boolean;
    androidVisible: boolean;
    iosVisible: boolean;
    kioskVisible: boolean;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }

  public is(moduleType: ModuleAppTypeEnum, filters?: any): boolean {
    return this.id === moduleType;
  }
}

export class ModuleAppClassesReserve extends ModuleApp {
  public activitiesGroups: string;

  public constructor(fields?: {
    id: number;
    text: string;
    homeButtonColumns: number;
    iconColor: string;
    textColor: string;
    guestModeEnabled: boolean;
    iconLink: string;
    isNovelty: boolean;
    showIcon: boolean;
    backgroundColor: string;
    borderRadius: string;
    borderColor: string;
    borderStyle: string;
    borderWidth: string;
    webVisible: boolean;
    androidVisible: boolean;
    iosVisible: boolean;
    kioskVisible: boolean;
    activitiesGroups: string;
  }) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }

  public is(moduleType: ModuleAppTypeEnum, filters?: { activitiesGroups: string }): boolean {
    return (
      super.is(moduleType) &&
      (!filters || !filters.activitiesGroups || (filters && filters.activitiesGroups && filters.activitiesGroups === this.activitiesGroups))
    );
  }
}

export class ModuleAppCollectiveZonesReserve extends ModuleApp {
  public collectiveZonesIds: string;

  public constructor(fields?: {
    id: number;
    text: string;
    homeButtonColumns: number;
    iconColor: string;
    textColor: string;
    guestModeEnabled: boolean;
    iconLink: string;
    isNovelty: boolean;
    showIcon: boolean;
    backgroundColor: string;
    borderRadius: string;
    borderColor: string;
    borderStyle: string;
    borderWidth: string;
    webVisible: boolean;
    androidVisible: boolean;
    iosVisible: boolean;
    kioskVisible: boolean;
    collectiveZonesIds: string;
  }) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }

  public is(moduleType: ModuleAppTypeEnum, filters?: { collectiveZonesIds: string }): boolean {
    return (
      super.is(moduleType) &&
      (!filters ||
        !filters.collectiveZonesIds ||
        (filters && filters.collectiveZonesIds && filters.collectiveZonesIds === this.collectiveZonesIds))
    );
  }
}

export class ModuleAppLink extends ModuleApp {
  public link: string;
  public inApp: boolean;

  public constructor(fields?: {
    id: number;
    text: string;
    homeButtonColumns: number;
    iconColor: string;
    textColor: string;
    guestModeEnabled: boolean;
    iconLink: string;
    isNovelty: boolean;
    showIcon: boolean;
    backgroundColor: string;
    borderRadius: string;
    borderColor: string;
    borderStyle: string;
    borderWidth: string;
    webVisible: boolean;
    androidVisible: boolean;
    iosVisible: boolean;
    kioskVisible: boolean;
    link: string;
    inApp: boolean;
  }) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class ModuleAppOpenApp extends ModuleApp {
  public schema: string;
  public appName: string;

  public constructor(fields?: {
    id: number;
    text: string;
    homeButtonColumns: number;
    iconColor: string;
    textColor: string;
    guestModeEnabled: boolean;
    iconLink: string;
    isNovelty: boolean;
    showIcon: boolean;
    backgroundColor: string;
    borderRadius: string;
    borderColor: string;
    borderStyle: string;
    borderWidth: string;
    webVisible: boolean;
    androidVisible: boolean;
    iosVisible: boolean;
    kioskVisible: boolean;
    schema: string;
    appName: string;
  }) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class ModuleAppContents extends ModuleApp {
  public idCategory?: number;

  public constructor(fields?: {
    id: number;
    text: string;
    homeButtonColumns: number;
    iconColor: string;
    textColor: string;
    guestModeEnabled: boolean;
    iconLink: string;
    isNovelty: boolean;
    showIcon: boolean;
    backgroundColor: string;
    borderRadius: string;
    borderColor: string;
    borderStyle: string;
    borderWidth: string;
    webVisible: boolean;
    androidVisible: boolean;
    iosVisible: boolean;
    kioskVisible: boolean;
    idCategory?: number;
  }) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }

  public is(moduleType: ModuleAppTypeEnum, filters?: { idCategory: number }): boolean {
    return (
      super.is(moduleType) && (!filters || !filters.idCategory || (filters && filters.idCategory && filters.idCategory === this.idCategory))
    );
  }
}

export class ModuleAppDistributor extends ModuleApp {
  public moduleType: number;

  public constructor(fields?: {
    id: number;
    text: string;
    homeButtonColumns: number;
    iconColor: string;
    textColor: string;
    guestModeEnabled: boolean;
    iconLink: string;
    isNovelty: boolean;
    showIcon: boolean;
    backgroundColor: string;
    borderRadius: string;
    borderColor: string;
    borderStyle: string;
    borderWidth: string;
    webVisible: boolean;
    androidVisible: boolean;
    iosVisible: boolean;
    kioskVisible: boolean;
    moduleType: number;
  }) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class ModuleAppContactForm extends ModuleApp {
  public idContactType?: number;
  public idContactCategory?: number;

  public constructor(fields?: {
    id: number;
    text: string;
    homeButtonColumns: number;
    iconColor: string;
    textColor: string;
    guestModeEnabled: boolean;
    iconLink: string;
    isNovelty: boolean;
    showIcon: boolean;
    backgroundColor: string;
    borderRadius: string;
    borderColor: string;
    borderStyle: string;
    borderWidth: string;
    webVisible: boolean;
    androidVisible: boolean;
    iosVisible: boolean;
    kioskVisible: boolean;
    idContactType?: number;
    idContactCategory?: number;
  }) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }
}

import { GlobalService } from './../global/global.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

//Registro de locale
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEu from '@angular/common/locales/eu';
import localeEn from '@angular/common/locales/en';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private translate: TranslateService,
    private globalService: GlobalService) {}

  /**
   * Establece el idioma de la APP
   */
  public setInitialLanguage(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      let defaultLanguage: string = this.globalService.language;

      if (window.Intl && typeof window.Intl === 'object' && navigator.language) {
        const langParts: string[] = navigator.language.split('-');

        if (langParts[0] === 'an'  ||  // aragones
            langParts[0] === 'ca'  ||  // catalan
            langParts[0] === 'gl'  ||  // gallego
            langParts[0] === 'ast' ||  // asturiano
            langParts[0] === 'eu'  ||  // euskera
            langParts[0] === 'es')     // español
        {
          this.globalService.language = 'es';

          if (langParts[0] === 'eu') {
            defaultLanguage = 'eu';
          }
          else {
            defaultLanguage = 'es';
          }
        }
      }

      registerLocaleData(localeEs, 'es');
      registerLocaleData(localeEn, 'en');
      registerLocaleData(localeEu, 'eu');

      this.translate.setDefaultLang(defaultLanguage);
      this.translate.use(defaultLanguage).subscribe(
        () => {
          resolve();
        },
        err => {
          reject(err);
        }
      );

    });
  }

  /**
   * Devuelve el recurso de texto con la clave indicada dependiendo
   * del idioma establecido.
   *
   * @param key Clave del recurso de texto
   */
  public getText(key: string | string[]): any {
    return this.translate.instant(key);
  }

  /**
   * Devuelve el recurso de texto con la clave indicada dependiendo
   * del idioma establecido.
   *
   * @param key Clave del recurso de texto
   */
  // public getTextObservable(key: string | string[]): Observable<any> {
  //   return this.translate.get(key);
  // }

}

/**
 *
 * Función factoria. Devuelve un TranslateHttpLoader que busca los ficheros
 * json con las traducciones en el directorio 'assets/i18n/'.
 *
 */
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

/**
 * Función que inicializa el servicio de lenguaje garantizando que los
 * textos del lenguaje seleccionado esten cargados para usarse sincronamente.
 *
 * @param languageService LanguageService
 */
export function initLanguageService(languageService: LanguageService) {
  return () => languageService.setInitialLanguage();
}

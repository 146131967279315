import { ModuleApp } from './module-app';

export class GroupModulesApp {
  public text: string;
  public modules: ModuleApp[];

  public constructor(fields?: {
    text: string;
    modules: ModuleApp[];
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class CollectiveZonesFee {
    public id: number;
    public name: string;

    public constructor(fields?: {
        id: number;
        name: string;
    }) {
        if (fields) {
            Object.assign(this, fields);
        }
    }
}

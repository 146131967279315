export class DistributorModule {
    public moduleType: number;
    public active: boolean;
    public moduleURL: string;

    public constructor(fields?: {
        moduleType: number;
        active: boolean;
        moduleURL: string;
    }) {
        if (fields) {
            Object.assign(this, fields);
        }
    }
}

export enum AppPaymentMethodEnum {
  pending = 0,
  visaPay = 1,
  bonus = 2,
  gateway = 3,
  fee = 11,
  payPal = 12,
  balanceBonus = 13,
  ofiBarman = 14,
  multibonus = 16,
  cashDro = 100,
  payTef = 101,
}

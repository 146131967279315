export class CourseInscriptionPendingPayment {
  public idCandidature: number;
  public nameCourse: string;
  public numberCourse: string;
  public dateInscription: Date;
  public datePaymentLimit: Date;
  public amount: number;

  public constructor(fields?: {
		idCandidature: number;
    nameCourse: string;
    numberCourse: string;
    dateInscription: Date;
    datePaymentLimit: Date;
    amount: number;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class ReserveFee {
    public id: number;
    public name: string;
    public sessions: number;
    public unlimitedSessions: boolean;

    public constructor(fields?: {
        id: number;
        name: string;
        sessions: number;
        unlimitedSessions: boolean;
    }) {
        if (fields) {
            Object.assign(this, fields);
        }
    }
}

export class CenterMulticenter {
    public idCenter: number;
    public name: string;
    public description: string;
    public imageCenterURL: string;
    public backgroundColor: string;
    public backgroundImageURL: string;
    public fontColor: string;
    public borderColor: string;
    public borderTam: string;
    public linkOnlineRegistration: string;

    public constructor(fields?: {
        idCenter: number;
        name: string;
        description: string;
        imageCenterURL: string;
        backgroundColor: string;
        backgroundImageURL: string;
        fontColor: string;
        borderColor: string;
        borderTam: string;
        linkOnlineRegistration: string;
    }) {
        if (fields) {
            Object.assign(this, fields);
        }
    }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Traductor
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from './shared/services/language/language.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';

// SHARED MODULE
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true,
      scrollPadding: false,
      scrollAssist: false
    }),
    IonicStorageModule.forRoot({
      // eslint-disable-next-line no-underscore-dangle
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: false
    }),
    SharedModule,
    AppRoutingModule
  ],
  providers: [
    { provide: Window, useValue: window },
    StatusBar,
    File,
    NativeAudio,
    SocialSharing,
    FingerprintAIO,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

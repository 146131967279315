import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataHttpService } from 'src/app/shared/services/data-http/data-http.service';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
// Servicios
import { GlobalService } from '../../../shared/services/global/global.service';
// MODELOS
import { Category } from 'src/app/shared/models/contents/category';
import { Content } from 'src/app/shared/models/contents/content';
import { Team } from 'src/app/shared/models/contents/team';

@Injectable()
export class ContentsService {

  constructor(
    private dataService: DataHttpService,
    private globalService: GlobalService
  ) { }

  /**************************************
   *       LISTADO DE CATEGORÍAS        *
  **************************************/
  // #region

  /**
   * Devuelve el listado de categorías de un centro.
   * @param idCenter Identificador de centro
   */
  public getCenterCategories(idCenter: number): Observable<Category[]> {
    return this.dataService.doGet('/centros/' + idCenter + '/categorias?tipoCategoria=estandar', 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        return response.body.map((category: any) => {
          return new Category({
            id: category.id,
            name: category.nombre,
          });
        });
      })
    );
  }

  // #endregion

  /**************************************
   *       LISTADO DE CONTENIDOS        *
   **************************************/
  // #region

  /**
   * Devuelve los contenidos de un centro por su tipo de visualización.
   * @param idCenter Identificador de centro
   * @param categoryType Tipo de categoría
   * @param categoryId Id de categoría
   */
  public getCenterCategoryContents(
    idCenter: number,
    categoryType: string,
    categoryId: number,
    pagLimit: number,
    pagOffset: number,
    pagTimestampBeginLoad: Date): Observable<Content[]> {

    let params: HttpParams = new HttpParams();

    if (pagLimit) {
      params = params.append('pagLimite', pagLimit.toString());
    }

    if (pagOffset != null && pagOffset !== undefined) {
      params = params.append('pagCompensacion', pagOffset.toString());
    }

    if (pagTimestampBeginLoad) {
      params = params.append('pagFechaCreacion', pagTimestampBeginLoad.toISOString());
    }

    if (categoryType) {
      params = params.append('tipoCategoria', categoryType);
    }

    if (categoryId) {
      params = params.append('idCategoria', categoryId.toString());
    }

    return this.dataService.doGet('/centros/' + idCenter + '/contenidos', 'v1.0', params).pipe(
      map((response: HttpResponse<Content[]>) => {
        return response.body.map((content: any) => {
          return new Content({
            id: content.id,
            category: content.categoria,
            title: content.titulo,
            summary: content.resumen,
            publishDate: new Date(moment(content.fechaPublicacion).format()),
            description: content.descripcion,
            externalLink: content.enlaceExterno,
            PDFLink: content.enlacePDF,
            base64Image: content.imagenBase64
          });
        });
      })
    );
  }

  /**
   * Devuelve el contenido de un centro.
   *
   * @param idCenter Identificador de centro
   * @param idContent Identificador de contenido
   */
   public getCenterContent(
    idCenter: number,
    idContent: number): Observable<Content> {
    return this.dataService.doGet('/centros/' + idCenter + '/contenidos/' + idContent, 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        return new Content({
          id: response.body.id,
          category: response.body.categoria,
          title: response.body.titulo,
          summary: response.body.resumen,
          publishDate: new Date(moment(response.body.fechaPublicacion).format()),
          description: response.body.descripcion,
          externalLink: response.body.enlaceExterno,
          PDFLink: response.body.enlacePDF,
          base64Image: response.body.imagenBase64
        });
      })
    );
  }

  // #endregion

  /**************************************
   *          CATEGORÍA EQUIPO          *
  **************************************/
  // #region

  /**
   * Devuelve los contenidos de la categoría equipo del centro
   * @param idCenter Identificador de centro
   * @param categoryId Id de categoría
  */
  public getTeamCategory(
    idCenter: number,
    categoryId: number): Observable<Team[]> {

    let params: HttpParams = new HttpParams();

    if (categoryId) {
      params = params.append('idCategoria', categoryId.toString());
    }

    return this.dataService.doGet('/centros/' + idCenter + '/equipo', 'v1.0', params).pipe(
      map((response: HttpResponse<any>) => {
        return response.body.map((team: any) => {
          return new Team({
            id: team.id,
            name: team.nombre,
		        description: team.descripcion,
		        outstanding: team.destacado,
		        phone: team.telefono,
            whatsApp: team.whatsApp,
		        email: team.email,
		        link: team.enlace,
		        base64Image: team.imagenBase64 ? team.imagenBase64 : this.globalService.defaultImage
          });
        });
      })
    );
  }

  //#endregion
}

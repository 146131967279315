export class ReservationPendingPayment {
  public id: number;
  public zoneName: string;
  public amount: number;
  public start: Date;
  public end: Date;
  public reservationDate: Date;

  public constructor(fields?: {
    id: number;
    zoneName: string;
    amount: number;
    start: Date;
    end: Date;
    reservationDate: Date;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

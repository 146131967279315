import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { DataHttpService } from 'src/app/shared/services/data-http/data-http.service';

// Modelos
import { Notification } from 'src/app/shared/models/notifications/notification';
import { NotificationImportanceEnum } from 'src/app/shared/models/notifications/notification-importance-enum';
import { NotificationTypeEnum } from 'src/app/shared/models/notifications/notification-type-enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  constructor(private dataService: DataHttpService) {}

  /**************************************
   *           NOTIFICACIONES           *
   **************************************/
  // #region

  /**
   * Devuelve las notificaciones de un socio.
   *
   * @param idMember Identificador de socio
   * @param pagLimit Paginacion: numero resultados
   * @param pagOffset Paginacion: offset
   * @param pagTimestampBeginLoad Paginacion: momento comienzo carga
   * @param importance Filtro importancia (opcional)
   * @param type Filtro tipo (opcional)
   * @param textDisplayNotification Recurso de textos para mostrar la notificación
   */
  public getNotifications(
    idMember: number,
    pagLimit: number,
    pagOffset: number,
    pagTimestampBeginLoad: Date,
    importance: NotificationImportanceEnum,
    type: NotificationTypeEnum,
    textDisplayNotification: any): Observable<Notification[]> {

    let params: HttpParams = new HttpParams();

    params = params.append('pagLimite', pagLimit.toString());
    params = params.append('pagCompensacion', pagOffset.toString());
    params = params.append('pagFechaCreacion', pagTimestampBeginLoad.toISOString());

    if (importance) {
      params = params.append('importancia', importance.toString());
    }

    if (type) {
      params = params.append('tipoContenido', type.toString());
    }

    return this.dataService.doGet('/socios/' + idMember + '/notificaciones', 'v1.0', params).pipe(
      map((response: HttpResponse<any>) => {

        const today = new Date(moment().format());

        return response.body.map((notification: any) => {
          const not: Notification = new Notification({
            id: notification.id,
            title: notification.titulo,
            message: notification.mensaje,
            detail: notification.detalle,
            url: notification.url,
            read: notification.leida,
            publishDate: new Date(moment(notification.fechaPublicacion).format()),
            type: notification.tipo,
            importance: notification.importancia
          });

          this.generateNotificationAntiquityDays(not, today);
          this.generateNotificationDisplayDate(not, today, textDisplayNotification);

          return not;
        });
      })
    );
  }

  /**
   * Devuelve las notificaciones de un socio.
   *
   * @param idMember Identificador de socio
   * @param idNotification Identificador de la notificacion
   * @param textDisplayNotification Recurso de textos para mostrar la notificación
   */
  public getNotification(
    idMember: number,
    idNotification: number,
    textDisplayNotification: any): Observable<Notification> {
    return this.dataService.doGet('/socios/' + idMember + '/notificaciones/' + idNotification, 'v1.0').pipe(
      map((response: HttpResponse<any>) => {

        const today = new Date(moment().format());

        const not: Notification = new Notification({
          id: response.body.id,
          title: response.body.titulo,
          message: response.body.mensaje,
          detail: response.body.detalle,
          url: response.body.url,
          read: response.body.leida,
          publishDate: new Date(moment(response.body.fechaPublicacion).format()),
          type: response.body.tipo,
          importance: response.body.importancia
        });

        this.generateNotificationAntiquityDays(not, today);
        this.generateNotificationDisplayDate(not, today, textDisplayNotification);

        return not;
      })
    );
  }

  /**
   * Devuelve el número de notificaciones no leidas de un socio.
   *
   * @param idMember Identificador de socio
   */
  public getUnreadNotifications(idMember: number): Observable<number> {
    return this.dataService.doGet('/socios/' + idMember + '/notificacionesnoleidas', 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );
  }

  /**
   * Actualiza una notificacion de un socio como leida.
   *
   * @param idMember Identificador de socio
   * @param idNotificacion Identificador de notificacion
   */
  public readNotification(idMember: number, idNotificacion: number): Observable<void> {
    return this.dataService.doPut('/socios/' + idMember + '/notificaciones/' + idNotificacion + '/leer', 'v1.0');
  }

  /**
   * Actualiza todas las notificaciones de un socio como leidas.
   *
   * @param idMember Identificador de socio
   */
  public readNotifications(idMember: number): Observable<void> {
    return this.dataService.doPut('/socios/' + idMember + '/notificaciones/leer', 'v1.0');
  }

  /**
   * Calcula los días de antiguedad que tiene una notificación a partir de una fecha
   *
   * @param notification Notificación
   * @param today Fecha actual
   */
  private generateNotificationAntiquityDays(notification: Notification, today: Date): void {
    const dayMilliseconds: number = 86400000; // 1000 * 60 * 60 * 24
    notification.antiquityDays =  Math.round(today.getTime() - notification.publishDate.getTime()) / (dayMilliseconds);
  }

  /**
   * Genera el texto a mostrar de antiguedad dependiendo de los días de antiguedad
   *
   * @param notification Notificación
   * @param today Fecha actual
   * @param textDisplayNotification Recurso de textos para mostrar la notificación
   */
  private generateNotificationDisplayDate(
    notification: Notification,
    today: Date,
    textDisplayNotification: any): void {

    if (notification.antiquityDays < 1 && notification.publishDate.getDate() === today.getDate()) {
      notification.displayDate = textDisplayNotification.LABEL_TODAY_AT.replace('@time', moment(notification.publishDate).format('HH:mm'));
    } else if ((notification.antiquityDays > 1 && notification.antiquityDays < 2) ||
              (notification.antiquityDays < 1 && notification.publishDate.getDate() !== today.getDate())) {
      notification.displayDate = textDisplayNotification.LABEL_YESTERDAY;
    } else if (notification.antiquityDays <= 14) {
      notification.displayDate = textDisplayNotification.LABEL_DAYS_AGO.replace('@days', notification.antiquityDays.toFixed(0));
    } else {
      notification.displayDate = moment(notification.publishDate).format('DD/MM/yyyy');
    }
  }

  // #endregion
}

import { Platform, NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { FirebaseCrash } from '@awesome-cordova-plugins/firebase-crash/ngx';
import { environment } from 'src/environments/environment';
import { Exception } from '../../models/others/exception';
import { LanguageService } from './../language/language.service';
import { GlobalService } from './../global/global.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class CrashesMonitorService {

  constructor(
    private platform: Platform,
    private globalService: GlobalService,
    private navController: NavController,
    private alertService: AlertService,
    private languageService: LanguageService,
    private firebaseCrash: FirebaseCrash) {}

  /**
   * Reporta una excepcion al equipo de desarrollo
   *
   * @param class Nombre de la clase
   * @param methodName Nombre del metodo
   * @param msg Mensaje
   */
  public reportException(className: string, methodName: string, msg: string): Promise<void> {
    return new Promise ((resolve, reject) => {
      const exp: Exception = {
        class: className,
        method: methodName,
        message: msg,
        idCenter: this.globalService.user ? this.globalService.user.idCenter.toString() : '-1',
        idMember: this.globalService.user ? this.globalService.user.idMember.toString() : '-1'
      };

      if (!environment.production) {
        console.error('ERROR -> ' + className + ' - ' + methodName + ' - ' + msg);
      }

      if (this.platform.is('cordova')) {
        this.firebaseCrash.logError(JSON.stringify(exp))
          .then((res: any) => {
            resolve();
          })
          .catch((error: any) => {
            reject();
          });
      } else {
        resolve();
      }
    });

  }

  /**
   * Reporta una excepcion al equipo de desarrollo y muestra un error al usuario.
   *
   * @param class Nombre de la clase
   * @param methodName Nombre del metodo
   * @param errStr Descripcion de la excepcion
   * @param msg Mensaje
   * @param navigation Navegacion
   */
  public reportAndShowException(fields: {
    className: string;
    methodName: string;
    errStr: string;
    msg?: string;
    navigation?: 'home' | 'back' | 'start';
  }): Promise<void> {

    return new Promise ((resolve, reject) => {

      this.reportException(fields.className, fields.methodName, fields.errStr).then(() => {
        resolve();
      })
      .catch((error: any) => {
        reject(error);
      });

      this.alertService.presentToast({
        msg: fields.msg ? fields.msg : this.languageService.getText('GLOBAL.ERROR_GENERIC_MSG'),
        position: 'top',
        duration: 4000
      });

      if (fields.navigation === 'home') {
        this.navController.navigateRoot('/');
      } else if (fields.navigation === 'back') {
        this.navController.back();
      } else if (fields.navigation === 'start') {
        this.navController.navigateRoot(this.globalService.startPage);
      }

    });
  }

}

import { CenterMulticenter } from './center-multicenter';

export class Multicenter {
  public backgroundHeaderColor: string;
  public fontHeaderColor: string;
  public logoURL: string;
  public loginBackgroundURL: string;
  public logoHomeWidth: number;
  public onlineRegistrationAndroidEnabled: boolean;
  public onlineRegistrationIOSEnabled: boolean;
  public entriesSellingEnabled: boolean;
  public guestModeEnabled: boolean;
  public centers: CenterMulticenter[];

  public constructor(fields?: {
    backgroundHeaderColor: string;
    fontHeaderColor: string;
    logoURL: string;
    loginBackgroundURL: string;
    logoHomeWidth: number;
    onlineRegistrationAndroidEnabled: boolean;
    onlineRegistrationIOSEnabled: boolean;
    entriesSellingEnabled: boolean;
    guestModeEnabled: boolean;
    centers: CenterMulticenter[];
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

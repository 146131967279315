export class MultibonusBase {
  id: string;
  name: string;
  balance: number;

  public constructor(fields?: { id: string; name: string; balance: number }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

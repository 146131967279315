import { DiscountType } from './discount-type';

export class Discount {
  public name: string;
  public amount: number;
  public type: DiscountType;

  public constructor(fields?: {
    name: string;
    amount: number;
    type: DiscountType;
  }) {
    if (fields) {
        Object.assign(this, fields);
    }
  }

}

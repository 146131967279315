import { LoginUser } from './login-user';

export class LoginUserResult {
  public loginUser: LoginUser;
  public token: string;

  public constructor(fields?: {
    loginUser: LoginUser;
    token: string;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

import { ShopBuyingLine } from 'src/app/shared/models/shop/shop-buying-line';

export class ShopPendingPayment {
  public buyingId: number;
  public netAmount: number;
  public pendingNetAmount: number;
  public grossAmount: number;
  public taxAmount: number;
  public buyingDate: Date;
  public buyingLines: ShopBuyingLine[];

  public constructor(fields?: {
    buyingId: number;
    netAmount: number;
    pendingNetAmount: number;
    grossAmount: number;
    taxAmount: number;
    buyingDate: Date;
    buyingLines: ShopBuyingLine[];
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

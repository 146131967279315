import { ShopPendingPayment } from 'src/app/shared/models/payments/shop-pending-payment';
import { FeePendingPayment } from 'src/app/shared/models/payments/fee-pending-payment';
import { ReservationPendingPayment } from 'src/app/shared/models/payments/reservation-pending-payment';
import { CourseInscriptionPendingPayment } from './course-inscription-pending-payment';

export class PendingPayments {
	public buyings: ShopPendingPayment[];
	public fees: FeePendingPayment[];
	public zoneReservations: ReservationPendingPayment[];
	public serviceReservations: ReservationPendingPayment[];
  public courseInscriptions: CourseInscriptionPendingPayment[];

  public constructor(fields?: {
		buyings: ShopPendingPayment[];
		fees: FeePendingPayment[];
		zoneReservations: ReservationPendingPayment[];
		serviceReservations: ReservationPendingPayment[];
    courseInscriptions: CourseInscriptionPendingPayment[];
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

import { LanguageService } from 'src/app/shared/services/language/language.service';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Injectable, NgZone } from '@angular/core';
import { FirebaseMessaging } from '@awesome-cordova-plugins/firebase-messaging/ngx';
import { Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

import { CrashesMonitorService } from 'src/app/shared/services/crashes-monitor/crashes-monitor.service';
import { DataHttpService } from 'src/app/shared/services/data-http/data-http.service';
import { GlobalService } from 'src/app/shared/services/global/global.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';

import { NotificationsService as CRMNotificationsService } from 'src/app/notifications/services/notifications/notifications.service';
import { ContentsService } from 'src/app/contents/services/contents/contents.service';
import { Notification as CRMNotification } from 'src/app/shared/models/notifications/notification';
import { Content } from '../../models/contents/content';

/**
 * Clase encargada de implementar el servicio de notificaciones desde Firebase.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private subscriptionCordovaOnTokenRefresh: Subscription;
  private subscriptionCordovaOnMessage: Subscription;
  private subscriptionCordovaOnBackgroundMessage: Subscription;

  constructor(
    private platform: Platform,
    private router: Router,
    private zone: NgZone,
    private globalService: GlobalService,
    private alertService: AlertService,
    private languageService: LanguageService,
    private dataHttpService: DataHttpService,
    private crashesMonitorService: CrashesMonitorService,
    private firebaseMessaging: FirebaseMessaging,
    private crmNotificationsService: CRMNotificationsService,
    private contentsService: ContentsService
  ) {
    this.subscriptionCordovaOnTokenRefresh = null;
    this.subscriptionCordovaOnMessage = null;
    this.subscriptionCordovaOnBackgroundMessage = null;
  }

  /**
   * Habilita las notificaciones en la APP
   */
  public enableNotificationsService(): Promise<void> {
    if (this.platform.is('cordova')) {
      return this.enableNotificationsServiceCordova();
    }

    return Promise.resolve();
  }

  /**
   * Deshabilita el servicio de notificaciones
   */
  public disableNotificationsService(): void {
    if (this.platform.is('cordova')) {
      this.firebaseMessaging.deleteToken().then(() => {
        if (this.subscriptionCordovaOnTokenRefresh) {
          this.subscriptionCordovaOnTokenRefresh.unsubscribe();
        }

        if (this.subscriptionCordovaOnMessage) {
          this.subscriptionCordovaOnMessage.unsubscribe();
        }

        if (this.subscriptionCordovaOnBackgroundMessage) {
          this.subscriptionCordovaOnBackgroundMessage.unsubscribe();
        }
      });
    }
  }

  /**
   * Actualiza el token de notificacion en el backend
   *
   * @param newToken Token
   * @private oldToken Token antiguo
   */
  private updateTokenApi(newToken: string, oldToken: string): Promise<any> {
    const that: NotificationsService = this;
    return new Promise<void>((resolve, reject) => {
      if (that.globalService.user) {
        let typeDevice: number = null;
        if (that.platform.is('android')) {
          typeDevice = 0;
        } else if (that.platform.is('ios')) {
          typeDevice = 1;
        }

        const body: any = {
          token: newToken,
          tokenOld: oldToken,
          tipo: typeDevice,
        };

        that.dataHttpService
          .doPost(
            '/socios/' + that.globalService.user.idMember + '/firebase',
            'v1.0',
            body
          )
          .subscribe(
            (response: HttpResponse<any>) => {
              if (response.status === 200) {
                resolve();
              } else {
                reject();
              }
            },
            (err) => {
              this.crashesMonitorService.reportException(
                'NotificationsService',
                'updateTokenApi',
                err
              );
              reject();
            }
          );
      } else {
        resolve();
      }
    });
  }

  //#region Cordova

  /**
   * Habilita las notificaciones en la APP en dispositivos moviles
   */
  private enableNotificationsServiceCordova(): Promise<void> {
    const that: NotificationsService = this;
    return new Promise<void>((resolve, reject) => {
      that.firebaseMessaging
        .requestPermission({ forceShow: true })
        .then((value: string) => {
          console.log(
            'OK Petición permiso notificación -> ' + JSON.stringify(value)
          );
        })
        .catch((error: any) => {
          console.error(
            'ERROR Petición permiso notificación -> ' + JSON.stringify(error)
          );
        });

      // Manejador evento token refresh
      that.subscriptionCordovaOnTokenRefresh = that.firebaseMessaging
        .onTokenRefresh()
        .subscribe(() => {
          console.log('Evento firebase onTokenRefresh');
          that.registerTokenOnBackendCordova();
        });

      // Manejador evento mensajes recibidos en primer plano (foreground)
      that.subscriptionCordovaOnMessage = that.firebaseMessaging
        .onMessage()
        .subscribe((data: any) => {
          console.log(
            'Notification received in foreground -> ' + JSON.stringify(data)
          );

          if (data.id && data.tipo) {
            this.alertService
              .presentConfirm({
                title:
                  this.languageService.getText('NOTIFICATIONS.PUSH.TITLE') +
                  data.gcm.title,
                msg:
                  data.gcm.body +
                  '<br><br>' +
                  this.languageService.getText('NOTIFICATIONS.PUSH.MSG'),
                textOk: this.languageService.getText(
                  'NOTIFICATIONS.PUSH.BTN_GO'
                ),
                textCancel: this.languageService.getText(
                  'NOTIFICATIONS.PUSH.BTN_NO_GO'
                ),
              })
              .then(async (val: boolean) => {
                if (val) {
                  if (data.tipo.toString() === '1') {
                    this.openNotificationPage(parseInt(data.id, 10));
                  } else if (data.id && data.tipo.toString() === '2') {
                    this.openContentPage(parseInt(data.id, 10));
                  }
                }
              });
          } else {
            this.alertService.presentConfirm({
              title:
                this.languageService.getText('NOTIFICATIONS.PUSH.TITLE') +
                data.gcm.title,
              msg: data.gcm.body,
              textOk: this.languageService.getText('NOTIFICATIONS.PUSH.BTN_GO'),
            });
          }
        });

      // Manejador evento mensajes recibidos en segundo plano (background)
      that.subscriptionCordovaOnBackgroundMessage = that.firebaseMessaging
        .onBackgroundMessage()
        .subscribe(async (data: any) => {
          console.log(
            'Click in notification received in background -> ' +
              JSON.stringify(data)
          );

          if (data.id && data.tipo.toString() === '1') {
            this.openNotificationPage(parseInt(data.id, 10));
          } else if (data.id && data.tipo.toString() === '2') {
            this.openContentPage(parseInt(data.id, 10));
          } else {
            await this.router.navigateByUrl('/');
          }
        });

      that
        .registerTokenOnBackendCordova()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  private async openNotificationPage(idNotification: number): Promise<void> {
    this.zone.run(() => {
      this.crmNotificationsService
        .getNotification(
          this.globalService.user.idMember,
          idNotification,
          this.languageService.getText('NOTIFICATIONS.MAIN')
        )
        .subscribe(
          async (notification: CRMNotification) => {
            await this.router.navigateByUrl(
              '/notifications/' + idNotification,
              { state: { notification } }
            );
          },
          (err) => {
            if (err.code !== 401) {
              this.crashesMonitorService.reportAndShowException({
                className: 'NotificationsService',
                methodName: 'openNotificationPage',
                errStr: err,
                msg: this.languageService.getText('GLOBAL.ERROR_GENERIC_MSG'),
                navigation: 'home',
              });
            }
          }
        );
    });
  }

  private openContentPage(idContent: number): void {
    this.zone.run(() => {
      this.contentsService
        .getCenterContent(this.globalService.user.idCenter, idContent)
        .subscribe(
          async (content: Content) => {
            await this.router.navigateByUrl('/contents/board/' + idContent, {
              state: { content },
            });
          },
          (err) => {
            if (err.code !== 401) {
              this.crashesMonitorService.reportAndShowException({
                className: 'NotificationsService',
                methodName: 'openContentPage',
                errStr: err,
                msg: this.languageService.getText('GLOBAL.ERROR_GENERIC_MSG'),
                navigation: 'home',
              });
            }
          }
        );
    });
  }

  /**
   * Registra el token de Firebase en dispositivos moviles
   */
  private registerTokenOnBackendCordova(): Promise<any> {
    const that: NotificationsService = this;
    return new Promise<void>((resolve, reject) => {
      that.firebaseMessaging
        .getToken()
        .then((token) => {
          console.log('fcm.getToken updateToken firebase (1) -> ' + token);
          that
            .updateTokenApi(token, this.globalService.tokenFirebase)
            .then(() => {
              console.log('fcm.getToken updateToken firebase (2) -> ' + token);
              that.globalService.setTokenFirebase(token);
              resolve();
            })
            .catch((err) => {
              console.log(
                'ERROR fcm.getToken updateToken firebase -> ' +
                  JSON.stringify(err)
              );
              reject(err);
            });
        })
        .catch((err) => {
          console.log('ERROR fcm.getToken firebase -> ' + JSON.stringify(err));
          this.crashesMonitorService.reportException(
            'NotificationsService',
            'registerTokenOnBackendCordova',
            JSON.stringify(err)
          );
          resolve();
        });
    });
  }

  //#endregion
}

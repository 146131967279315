import { GroupModulesApp } from './../../../shared/models/configuration-app/group-modules-app';
import {
  ModuleApp,
  ModuleAppClassesReserve,
  ModuleAppCollectiveZonesReserve,
  ModuleAppLink,
  ModuleAppContents,
  ModuleAppDistributor,
  ModuleAppContactForm,
  ModuleAppOpenApp,
} from 'src/app/shared/models/configuration-app/module-app';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataHttpService } from 'src/app/shared/services/data-http/data-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
// Modelos
import { ConfigurationApp } from 'src/app/shared/models/configuration-app/configuration-app';
import {
  Location,
  MapPoint,
} from 'src/app/shared/models/configuration-app/location';
import { CenterMulticenter } from 'src/app/shared/models/configuration-app/center-multicenter';
import { Multicenter } from 'src/app/shared/models/configuration-app/multicenter';
import { ModuleAppTypeEnum } from 'src/app/shared/models/configuration-app/module-app-type-enum';
import { DistributorModule } from 'src/app/shared/models/distributor-module/distributor-module';
import { GlobalService } from 'src/app/shared/services/global/global.service';
import { ModeKioskEnum } from 'src/app/shared/models/others/mode-kiosk.enum';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  constructor(
    private dataService: DataHttpService,
    private platform: Platform,
    private globalService: GlobalService
  ) {}

  /**
   * Devuelve la configuracion de la APP
   *
   * @param idCenter Identificador de centro
   */
  public getAppConfig(idCenter: number): Observable<ConfigurationApp> {
    return this.dataService
      .doGet('/centros/' + idCenter + '/configuracionapp', 'v1.0', null)
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.body) {
            const config: ConfigurationApp = new ConfigurationApp({
              name: response.body.nombre,
              logoURL: response.body.logoURL,
              loginBackgroundURL: response.body.loginBackgroundURL,
              homeBackgroundURL: response.body.homeBackgroundURL,
              descriptionCenter: response.body.descripcionCentro,
              dataProtectionConditions:
                response.body.condicionesProteccionDatos,
              useConditions: response.body.condicionesUso,
              guestModeEnabled:
                response.body.ModoInvitadoActivo !== null &&
                response.body.modoInvitadoActivo !== undefined
                  ? response.body.modoInvitadoActivo
                  : false,
              storeOptProductsEnabled:
                response.body.tiendaOpcProductosActivo !== null &&
                response.body.tiendaOpcProductosActivo !== undefined
                  ? response.body.tiendaOpcProductosActivo
                  : false,
              storeOptBonusEnabled:
                response.body.tiendaOpcBonosActivo !== null &&
                response.body.tiendaOpcBonosActivo !== undefined
                  ? response.body.tiendaOpcBonosActivo
                  : false,
              storeOptHiringsEnabled:
                response.body.tiendaOpcContratacionesActivo !== null &&
                response.body.tiendaOpcContratacionesActivo !== undefined
                  ? response.body.tiendaOpcContratacionesActivo
                  : false,
              storeOptEntriesEnabled:
                response.body.tiendaOpcEntradasActivo !== null &&
                response.body.tiendaOpcEntradasActivo !== undefined
                  ? response.body.tiendaOpcEntradasActivo
                  : false,
              onlineRegistrationAndroidEnabled:
                response.body.altaOnlineAndroidActivo !== null &&
                response.body.altaOnlineAndroidActivo !== undefined
                  ? response.body.altaOnlineAndroidActivo
                  : false,
              onlineRegistrationIOSEnabled:
                response.body.altaOnlineIOSActivo !== null &&
                response.body.altaOnlineIOSActivo !== undefined
                  ? response.body.altaOnlineIOSActivo
                  : false,
              entriesSellingEnabled:
                response.body.ventaEntradasActivo !== null &&
                response.body.ventaEntradasActivo !== undefined
                  ? response.body.ventaEntradasActivo
                  : false,
              loginKioskQREnabled:
                response.body.iniciarKioskoConQRActivo !== null &&
                response.body.iniciarKioskoConQRActivo !== undefined
                  ? response.body.iniciarKioskoConQRActivo
                  : false,
              loginKioskDeviceEnabled:
                response.body.iniciarKioskoConDispositivoActivo !== null &&
                response.body.iniciarKioskoConDispositivoActivo !== undefined
                  ? response.body.iniciarKioskoConDispositivoActivo
                  : false,
              loginStoreOptProductsEnabled:
                response.body.tiendaLoginOpcProductosActivo !== null &&
                response.body.tiendaLoginOpcProductosActivo !== undefined
                  ? response.body.tiendaLoginOpcProductosActivo
                  : false,
              loginStoreOptEntriesEnabled:
                response.body.tiendaLoginOpcEntradasActivo !== null &&
                response.body.tiendaLoginOpcEntradasActivo !== undefined
                  ? response.body.tiendaLoginOpcEntradasActivo
                  : false,
              linkedCentersEnabled: response.body.centrosVinculadosActivo,
              linkOnlineRegistration: response.body.enlaceAltaOnline,
              linkWebCenter: response.body.enlaceWebCentro,
              linkFacebook: response.body.enlaceFacebook,
              linkInstagram: response.body.enlaceInstagram,
              linkTwitter: response.body.enlaceTwitter,
              linkYoutube: response.body.enlaceYoutube,
              linkTikTok: response.body.enlaceTikTok,
              linkTelegram: response.body.enlaceTelegram,
              linkLinkedIn: response.body.enlaceLinkedIn,
              linkSnapchat: response.body.enlaceSnapchat,
              whatsAppCenter: response.body.whatsApp,
              phoneCenter: response.body.telefono,
              emailCenter: response.body.email,
              colorPrimaryBase: response.body.colorPrimaryBase,
              colorPrimaryBaseRGB: response.body.colorPrimaryBaseRGB,
              colorPrimaryContrast: response.body.colorPrimaryContrast,
              colorPrimaryContrastRGB: response.body.colorPrimaryContrastRGB,
              colorPrimaryShade: response.body.colorPrimaryShade,
              colorPrimaryTint: response.body.colorPrimaryTint,
              colorBackgroundSolidBase: response.body.colorBackgroundSolidBase,
              colorBackgroundSolidBaseRGB:
                response.body.colorBackgroundSolidBaseRGB,
              colorBackgroundSolidContrast:
                response.body.colorBackgroundSolidContrast,
              colorBackgroundSolidContrastRGB:
                response.body.colorBackgroundSolidContrastRGB,
              colorBackgroundSolidShade:
                response.body.colorBackgroundSolidShade,
              colorBackgroundSolidTint: response.body.colorBackgroundSolidTint,
              colorHeaderBase: response.body.colorHeaderBase,
              colorHeaderBaseRGB: response.body.colorHeaderBaseRGB,
              colorHeaderContrast: response.body.colorHeaderContrast,
              colorHeaderContrastRGB: response.body.colorHeaderContrastRGB,
              colorHeaderShade: response.body.colorHeaderShade,
              colorHeaderTint: response.body.colorHeaderTint,
              colorButtonMainBase: response.body.colorButtonMainBase,
              colorButtonMainBaseRGB: response.body.colorButtonMainBaseRGB,
              colorButtonMainContrast: response.body.colorButtonMainContrast,
              colorButtonMainContrastRGB:
                response.body.colorButtonMainContrastRGB,
              colorButtonMainShade: response.body.colorButtonMainShade,
              colorButtonMainTint: response.body.colorButtonMainTint,
              colorListBackgroundBase: response.body.colorListBackgroundBase,
              colorListBackgroundBaseRGB:
                response.body.colorListBackgroundBaseRGB,
              colorListBackgroundContrast:
                response.body.colorListBackgroundContrast,
              colorListBackgroundContrastRGB:
                response.body.colorListBackgroundContrastRGB,
              colorListBackgroundShade: response.body.colorListBackgroundShade,
              colorListBackgroundTint: response.body.colorListBackgroundTint,
              colorButtonPositiveBase: response.body.colorButtonPositiveBase,
              colorButtonPositiveBaseRGB:
                response.body.colorButtonPositiveBaseRGB,
              colorButtonPositiveContrast:
                response.body.colorButtonPositiveContrast,
              colorButtonPositiveContrastRGB:
                response.body.colorButtonPositiveContrastRGB,
              colorButtonPositiveShade: response.body.colorButtonPositiveShade,
              colorButtonPositiveTint: response.body.colorButtonPositiveTint,
              colorButtonNegativeBase: response.body.colorButtonNegativeBase,
              colorButtonNegativeBaseRGB:
                response.body.colorButtonNegativeBaseRGB,
              colorButtonNegativeContrast:
                response.body.colorButtonNegativeContrast,
              colorButtonNegativeContrastRGB:
                response.body.colorButtonNegativeContrastRGB,
              colorButtonNegativeShade: response.body.colorButtonNegativeShade,
              colorButtonNegativeTint: response.body.colorButtonNegativeTint,
              fontFamily: response.body.fontFamily,
              fontSize: response.body.fontSize,
              colorText: response.body.colorText,
              colorBackground: response.body.colorBackground,
              colorError: response.body.colorError,
              colorBorder: response.body.colorBorder,
              colorListBorder: response.body.colorListBorder,
              textColorListTitle: response.body.textColorListTitle,
              textColorListGroupTitle: response.body.textColorListGroupTitle,
              textColorPageDetailTitle: response.body.textColorPageDetailTitle,
              colorDatatableBorder: response.body.colorDatatableBorder,
              colorDatatableHeaderBackground:
                response.body.colorDatatableHeaderBackground,
              colorDatatableHeaderText: response.body.colorDatatableHeaderText,
              colorGraphLine1: response.body.colorGraphLine1,
              colorGraphLine2: response.body.colorGraphLine2,
              colorBackgroundBadge: response.body.colorBackgroundBadge,
              colorFontBadge: response.body.colorFontBadge,
              selectZonesParters: response.body.seleccionarAcompañantesZonas,
              logoHomeWidth: response.body.logoHomeWidth,
              locationFocus:
                response.body.localizacionMapaCentrarLat &&
                response.body.localizacionMapaCentrarLong
                  ? new MapPoint({
                      lat: response.body.localizacionMapaCentrarLat,
                      lng: response.body.localizacionMapaCentrarLong,
                    })
                  : null,
              locationZoom: response.body.localizacionMapaZoom,
              locations: response.body.localizaciones
                ? (response.body.localizaciones as any[]).map((l) => {
                    return new Location({
                      lat: l.latitud,
                      lng: l.longitud,
                      description: l.descripcion,
                    });
                  })
                : null,
              images: response.body.imagenesCentro,
              modulesHome: response.body.modulosHome
                .map((module: any) => {
                  return this.generateModuleApp(module);
                })
                .filter((module: ModuleApp) => {
                  return this.getModuleVisibility(module);
                }),
              modulesMenu: response.body.modulosMenu
                .map((module: any) => {
                  return this.generateModuleApp(module);
                })
                .filter((module: ModuleApp) => {
                  return this.getModuleVisibility(module);
                }),
              groupsModulesMenu: response.body.gruposModulosMenu.map(
                (group: any) => {
                  return new GroupModulesApp({
                    text: group.texto,
                    modules: group.modulos
                      .map((mod: any) => {
                        return this.generateModuleApp(mod);
                      })
                      .filter((module: ModuleApp) => {
                        return this.getModuleVisibility(module);
                      }),
                  });
                }
              ),
            });

            return config;
          }
        })
      );
  }

  /**
   * Devuelve los centros de una APP multicentro
   *
   * @param idMulticenter Identificador de APP multicentro
   */
  public getCentersMulticenter(idMulticenter: number): Observable<Multicenter> {
    return this.dataService
      .doGet('/configuracionapp/multicentros/' + idMulticenter, 'v1.0', null)
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.body) {
            return new Multicenter({
              backgroundHeaderColor: response.body.colorBackgroundHeader,
              fontHeaderColor: response.body.colorFontHeader,
              loginBackgroundURL: response.body.loginBackgroundURL,
              logoURL: response.body.logoURL,
              logoHomeWidth: response.body.logoHomeWidth,
              onlineRegistrationAndroidEnabled:
                response.body.altaOnlineAndroidActivo,
              onlineRegistrationIOSEnabled: response.body.altaOnlineIOSActivo,
              entriesSellingEnabled: response.body.ventaEntradasActivo,
              guestModeEnabled: response.body.modoInvitadoActivo,
              centers: response.body.centros.map((c: any) => {
                return new CenterMulticenter({
                  idCenter: c.idCentro,
                  name: c.nombre,
                  description: c.descripcion,
                  imageCenterURL: c.imagenCentroURL,
                  backgroundColor: c.backgroundColor,
                  backgroundImageURL: c.backgroundImagenURL,
                  fontColor: c.fontColor,
                  borderColor: c.borderColor,
                  borderTam: c.borderTam,
                  linkOnlineRegistration: c.enlaceAltaOnline,
                });
              }),
            });
          }
        })
      );
  }

  /**
   * Devuelve la configuración de los módulos de distribuidor
   *
   * @param idMember Identificador de socio
   */
  public getDistributorModulesConfig(
    idMember: number
  ): Observable<DistributorModule[]> {
    return this.dataService
      .doGet('/socios/' + idMember + '/distribuidor/modulos', 'v1.0', null)
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.body) {
            return response.body.map((distributorModule: any) => {
              return new DistributorModule({
                moduleType: distributorModule.tipoModulo,
                active: distributorModule.activo,
                moduleURL: distributorModule.urlModulo,
              });
            });
          }
        })
      );
  }

  /**
   * Devuelve si el usuario es administrador del módulo de chat en un centro
   *
   * @param idCenter Identificador de centro
   * @param idMember Identificador de socio
   */
  public getChatAdmin(idCenter: number, idMember: number): Observable<boolean> {
    return this.dataService
      .doGet(
        '/centros/' + idCenter + '/socios/' + idMember + '/chat/administrador',
        'v1.0',
        null
      )
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  /**
   * Devuelve la URL del alta online del distribuidor del socio
   *
   * @param idMember Identificador de socio
   */
  public getDistributorLinkOnlineRegistration(
    idMember: number
  ): Observable<string> {
    return this.dataService
      .doGet('/socios/' + idMember + '/distribuidor/altaonline', 'v1.0', null)
      .pipe(
        map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }

  /**
   * Instancia objetos ModuleApp a partir de los datos devueltos por la API
   *
   * @param module Datos del modulo
   */
  private generateModuleApp(module: any): ModuleApp {
    if (module.id === ModuleAppTypeEnum.classesReserve) {
      return new ModuleAppClassesReserve({
        id: module.id,
        text: module.texto,
        homeButtonColumns: module.botonHomeColumnas,
        iconColor: module.colorIcono,
        textColor: module.colorTexto,
        guestModeEnabled: module.modoInvitadoActivo,
        iconLink: module.enlaceIcono,
        isNovelty: module.esNovedad,
        showIcon: module.mostrarIcono,
        backgroundColor: module.colorFondo,
        borderRadius: module.radioBorde,
        borderColor: module.colorBorde,
        borderStyle: module.estiloBorde,
        borderWidth: module.anchoBorde,
        webVisible: module.visibleWeb,
        androidVisible: module.visibleAndroid,
        iosVisible: module.visibleIOS,
        kioskVisible: module.visibleKiosko,
        activitiesGroups: module.gruposActividades,
      });
    } else if (module.id === ModuleAppTypeEnum.collectiveZonesReserve) {
      return new ModuleAppCollectiveZonesReserve({
        id: module.id,
        text: module.texto,
        homeButtonColumns: module.botonHomeColumnas,
        iconColor: module.colorIcono,
        textColor: module.colorTexto,
        guestModeEnabled: module.modoInvitadoActivo,
        iconLink: module.enlaceIcono,
        isNovelty: module.esNovedad,
        showIcon: module.mostrarIcono,
        backgroundColor: module.colorFondo,
        borderRadius: module.radioBorde,
        borderColor: module.colorBorde,
        borderStyle: module.estiloBorde,
        borderWidth: module.anchoBorde,
        webVisible: module.visibleWeb,
        androidVisible: module.visibleAndroid,
        iosVisible: module.visibleIOS,
        kioskVisible: module.visibleKiosko,
        collectiveZonesIds: module.idsZonasColectivas,
      });
    } else if (module.id === ModuleAppTypeEnum.link) {
      return new ModuleAppLink({
        id: module.id,
        text: module.texto,
        homeButtonColumns: module.botonHomeColumnas,
        iconColor: module.colorIcono,
        textColor: module.colorTexto,
        guestModeEnabled: module.modoInvitadoActivo,
        iconLink: module.enlaceIcono,
        isNovelty: module.esNovedad,
        showIcon: module.mostrarIcono,
        backgroundColor: module.colorFondo,
        borderRadius: module.radioBorde,
        borderColor: module.colorBorde,
        borderStyle: module.estiloBorde,
        borderWidth: module.anchoBorde,
        webVisible: module.visibleWeb,
        androidVisible: module.visibleAndroid,
        iosVisible: module.visibleIOS,
        kioskVisible: module.visibleKiosko,
        link: module.enlace,
        inApp: module.inApp,
      });
    } else if (module.id === ModuleAppTypeEnum.openApp) {
      return new ModuleAppOpenApp({
        id: module.id,
        text: module.texto,
        homeButtonColumns: module.botonHomeColumnas,
        iconColor: module.colorIcono,
        textColor: module.colorTexto,
        guestModeEnabled: module.modoInvitadoActivo,
        iconLink: module.enlaceIcono,
        isNovelty: module.esNovedad,
        showIcon: module.mostrarIcono,
        backgroundColor: module.colorFondo,
        borderRadius: module.radioBorde,
        borderColor: module.colorBorde,
        borderStyle: module.estiloBorde,
        borderWidth: module.anchoBorde,
        webVisible: module.visibleWeb,
        androidVisible: module.visibleAndroid,
        iosVisible: module.visibleIOS,
        kioskVisible: module.visibleKiosko,
        schema: module.esquema,
        appName: module.nombreApp,
      });
    } else if (
      module.id === ModuleAppTypeEnum.board ||
      module.id === ModuleAppTypeEnum.activities ||
      module.id === ModuleAppTypeEnum.team
    ) {
      return new ModuleAppContents({
        id: module.id,
        text: module.texto,
        homeButtonColumns: module.botonHomeColumnas,
        iconColor: module.colorIcono,
        textColor: module.colorTexto,
        guestModeEnabled: module.modoInvitadoActivo,
        iconLink: module.enlaceIcono,
        isNovelty: module.esNovedad,
        showIcon: module.mostrarIcono,
        backgroundColor: module.colorFondo,
        borderRadius: module.radioBorde,
        borderColor: module.colorBorde,
        borderStyle: module.estiloBorde,
        borderWidth: module.anchoBorde,
        webVisible: module.visibleWeb,
        androidVisible: module.visibleAndroid,
        iosVisible: module.visibleIOS,
        kioskVisible: module.visibleKiosko,
        idCategory: module.idCategoria,
      });
    } else if (module.id === ModuleAppTypeEnum.distributor) {
      return new ModuleAppDistributor({
        id: module.id,
        text: module.texto,
        homeButtonColumns: module.botonHomeColumnas,
        iconColor: module.colorIcono,
        textColor: module.colorTexto,
        guestModeEnabled: module.modoInvitadoActivo,
        iconLink: module.enlaceIcono,
        isNovelty: module.esNovedad,
        showIcon: module.mostrarIcono,
        backgroundColor: module.colorFondo,
        borderRadius: module.radioBorde,
        borderColor: module.colorBorde,
        borderStyle: module.estiloBorde,
        borderWidth: module.anchoBorde,
        webVisible: module.visibleWeb,
        androidVisible: module.visibleAndroid,
        iosVisible: module.visibleIOS,
        kioskVisible: module.visibleKiosko,
        moduleType: module.tipoModulo,
      });
    } else if (module.id === ModuleAppTypeEnum.contactForm) {
      return new ModuleAppContactForm({
        id: module.id,
        text: module.texto,
        homeButtonColumns: module.botonHomeColumnas,
        iconColor: module.colorIcono,
        textColor: module.colorTexto,
        guestModeEnabled: module.modoInvitadoActivo,
        iconLink: module.enlaceIcono,
        isNovelty: module.esNovedad,
        showIcon: module.mostrarIcono,
        backgroundColor: module.colorFondo,
        borderRadius: module.radioBorde,
        borderColor: module.colorBorde,
        borderStyle: module.estiloBorde,
        borderWidth: module.anchoBorde,
        webVisible: module.visibleWeb,
        androidVisible: module.visibleAndroid,
        iosVisible: module.visibleIOS,
        kioskVisible: module.visibleKiosko,
        idContactType: module.idTipoContacto,
        idContactCategory: module.idCategoriaContacto,
      });
    } else {
      return new ModuleApp({
        id: module.id,
        text: module.texto,
        homeButtonColumns: module.botonHomeColumnas,
        iconColor: module.colorIcono,
        textColor: module.colorTexto,
        guestModeEnabled: module.modoInvitadoActivo,
        iconLink: module.enlaceIcono,
        isNovelty: module.esNovedad,
        showIcon: module.mostrarIcono,
        backgroundColor: module.colorFondo,
        borderRadius: module.radioBorde,
        borderColor: module.colorBorde,
        borderStyle: module.estiloBorde,
        borderWidth: module.anchoBorde,
        webVisible: module.visibleWeb,
        androidVisible: module.visibleAndroid,
        iosVisible: module.visibleIOS,
        kioskVisible: module.visibleKiosko,
      });
    }
  }

  private getModuleVisibility(module: ModuleApp): boolean {
    if (this.platform.is('cordova')) {
      if (this.platform.is('android') && module.androidVisible) {
        return true;
      } else if (this.platform.is('ios') && module.iosVisible) {
        return true;
      }
    } else if (
      this.globalService.kioskMode !== ModeKioskEnum.disabled &&
      module.kioskVisible
    ) {
      return true;
    } else if (
      this.globalService.kioskMode === ModeKioskEnum.disabled &&
      module.webVisible
    ) {
      return true;
    }

    return false;
  }
}

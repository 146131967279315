import { MemberSexEnum } from '../members/member-sex-enum.enum';
import { MemberTypeDocumentEnum } from '../members/member-type-document-enum';

export class MemberPersonalData {
    public id: number;
    public name: string;
    public surname: string;
    public document: string;
    public typeDocument: MemberTypeDocumentEnum;
    public birthdate: Date;
    public sex: MemberSexEnum;
    public email: string;
    public mobilePhone: string;
    public landline: string;
    public address: MemberAddress;
    public allergies: string;
    public memberAuthorizedPeople: MemberAuthorizedPerson[];

    public constructor(fields?: {
        id: number;
        name: string;
        surname: string;
        document: string;
        typeDocument: MemberTypeDocumentEnum;
        birthdate: Date;
        sex: MemberSexEnum;
        email: string;
        mobilePhone: string;
        landline: string;
        address: MemberAddress;
        allergies: string;
        memberAuthorizedPeople: MemberAuthorizedPerson[];
    }) {
      if (fields) {
        Object.assign(this, fields);
      }
    }
}

export class MemberAddress {
    public address: string;
    public province: string;
    public locality: string;
    public country: string;
    public zipcode: string;

    public constructor(fields?: {
        address: string;
        province: string;
        locality: string;
        country: string;
        zipcode: string;
      }) {
        if (fields) {
          Object.assign(this, fields);
        }
    }
}

export class MemberAuthorizedPerson {
    public id: number;
    public name: string;
    public surname: string;
    public document: string;
    public typeDocument: MemberTypeDocumentEnum;
    public email: string;
    public mobilePhone: string;

    public constructor(fields?: {
        id: number;
        name: string;
        surname: string;
        document: string;
        typeDocument: MemberTypeDocumentEnum;
        email: string;
        mobilePhone: string;
      }) {
        if (fields) {
          Object.assign(this, fields);
        }
    }
}

export class LoginUser {
  public idUser: number;
  public idMember: number;
  public idCenter: number;

  public constructor(fields?: {
    idUser: number;
    idMember: number;
    idCenter: number;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

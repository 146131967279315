import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { AlertService } from '../alert/alert.service';
import { LanguageService } from '../language/language.service';
import { GlobalService } from '../global/global.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private navController: NavController,
    private loadingCtrl: LoadingController,
    private alertController: AlertController,
    private popoverController: PopoverController,
    private modalCtrl: ModalController,
    private router: Router,
    private alertService: AlertService,
    private languageService: LanguageService,
    private globalService: GlobalService
  ) { }

  public async back(): Promise<void> {
    if (await this.closePopovers()) {

      if (this.router.url.includes('/training/dosession')){
        return;
      }

      const nav: any = this.navController;

      if (nav.router.currentUrlTree.root.numberOfChildren === 0) {
        this.alertService.presentConfirm({
          title: this.languageService.getText('GLOBAL.ATTENTION'),
          msg: this.languageService.getText('CORE.BACK_BUTTON.TITLE_GO_OUT')
        }).then((val: boolean) => {
          if (val) {
            (navigator as any).app.exitApp();
          }
        });
      } else {
        this.navController.back();
      }
    }
  }

  public async root(): Promise<void> {
    await this.closePopovers();

    if (this.router.url.includes('/training/dosession')){
      return;
    }

    if (this.globalService.user || this.globalService.guestMode) {
      this.navController.navigateRoot('/');
    } else {
      this.navController.navigateRoot(this.globalService.startPage);
    }
  }

  public async closePopovers(): Promise<boolean> {
    const loading: HTMLIonLoadingElement = await this.loadingCtrl.getTop();

    if (loading) {
      await loading.dismiss();
    }

    const alert: HTMLIonAlertElement = await this.alertController.getTop();

    if (alert) {
      await alert.dismiss();
    }

    const popover: HTMLIonPopoverElement = await this.popoverController.getTop();

    if (popover) {
      await popover.dismiss();
    }

    const modal: HTMLIonModalElement = await this.modalCtrl.getTop();

    if (modal) {
      await modal.dismiss();
    }

    return !alert && !popover && !modal;
  }
}

import { MemberBase } from './member';

export class MemberGroupBase {
  public id: number;
  public name: string;

  public constructor(fields?: {
    id: number;
    name: string;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }

}

export class MemberGroup extends MemberGroupBase {
  public code: string;
  public idMemberTitular: number;
  public memberTitular: MemberBase;
  public members: MemberBase[];

  public constructor(fields?: {
    id: number;
    name: string;
    code: string;
    idMemberTitular: number;
    members: MemberBase[];
  }) {
    super();

    if (fields) {
      Object.assign(this, fields);
      this.memberTitular = this.members.find(m => m.id === this.idMemberTitular);
    }
  }

}

import { BonusTypeEnum } from './bonus-type.enum';

export class BalanceBonusBase {
  public id: number;
  public name: string;
  public balance: number;

  public constructor(fields?: { id: number; name: string; balance: number }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class BalanceBonus extends BalanceBonusBase {
  public type: BonusTypeEnum;
  public expiringDate: Date;

  public constructor(fields?: {
    id: number;
    name: string;
    balance: number;
    type: BonusTypeEnum;
    expiringDate: Date;
  }) {
    super();
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

import { GsmKey } from '../gsm-keys/gsm-key';

export class ShopBuyingLine {
	public amount: number;
	public pendingAmount: number;
	public discountPercentage: number;
	public product: string;
	public productImage: string;
	public units: number;
	public gsmKey: GsmKey;
	public date?: Date;

    public constructor(fields?: {
		amount: number;
		pendingAmount: number;
		discountPercentage: number;
		product: string;
		productImage: string;
		units: number;
		gsmKey: GsmKey;
		date?: Date;
    }) {
      if (fields) {
          Object.assign(this, fields);
      }
    }
}

export class Team {
  public id: number;
  public name: string;
  public description: string;
  public outstanding: boolean;
  public phone: string;
  public whatsApp: string;
  public email: string;
  public link: string;
  public base64Image: string;

  public constructor(fields?: {
    id: number;
    name: string;
    description: string;
    outstanding: boolean;
    phone: string;
    whatsApp: string;
    email: string;
    link: string;
    base64Image: string;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
